import React from 'react';

import './Box.css';

interface IBoxProps {
  children: React.ReactNode;
}

export const Box: React.FC<IBoxProps> = ({ children }) => {
  return <div className="box">{children}</div>;
};
