import _ from 'lodash';
import { IFeesExpensesValue, IPcrEntityWorkbook } from 'types/excelTypes';
import {
  sheetBool,
  sheetCaseNumber,
  sheetDate,
  sheetNumber,
  sheetText,
  sheetTextNoSpace,
} from './mappingUtils';
import { mapPcrStruct } from './mapStructurePcr';

//to shortenning parameters
const fieldName = {
  first: mapPcrStruct.firstPage,
  part1: mapPcrStruct.part1_Transfers,
  part3: mapPcrStruct.part3_RecoveriesOfClaimsAndInterests,
  part4: mapPcrStruct.part4_Questionnaire,
  final: mapPcrStruct.finalSignature,
};

/* prettier-ignore */
export const mappingPcr = (data: IPcrEntityWorkbook) => {
  const pageFirst = data.fields['Page 1'];
  const pagePart1_4 = data.fields['Parts 1-4'];
  const pageFinal = data.fields['Responsible Party'];

  const part2a = data.feesAndExpenses['Part 2a'];
  const part2b = data.feesAndExpenses['Part 2b'];
  const part2c = sheet2cSummaryValue(data.feesAndExpenses['Part 2c'], part2a, part2b)

  return {
    firstPage: {
      districtQualifier: sheetText(pageFirst, fieldName.first.districtQualifier), 
      districtState: sheetText(pageFirst, fieldName.first.districtState), 
      courtDivision: sheetText(pageFirst, fieldName.first.courtDivision), 
      debtors: sheetText(pageFirst, fieldName.first.debtors), 
      caseNo: sheetCaseNumber(pageFirst, fieldName.first.caseNo), 
      leadCaseNo: sheetCaseNumber(pageFirst, fieldName.first.leadCaseNo), 
      isJointlyAdministered: sheetBool(pageFirst, fieldName.first.isJointlyAdministered), 
      reportType: sheetTextNoSpace(pageFirst, fieldName.first.reportType), 
      quarterEndingDate: sheetDate(pageFirst, fieldName.first.quarterEndingDate), 
      petitionDate: sheetDate(pageFirst, fieldName.first.petitionDate), 
      planConfirmedDate: sheetDate(pageFirst, fieldName.first.planConfirmedDate), 
      planEffectiveDate: sheetDate(pageFirst, fieldName.first.planEffectiveDate), 
      reportingParty: sheetTextNoSpace(pageFirst, fieldName.first.reportingParty), 
      otherPartyName: sheetText(pageFirst, fieldName.first.otherPartyName), 
      signatureDate: sheetDate(pageFirst, fieldName.first.signatureDate), 
      responsiblePartySignature: sheetText(pageFirst, fieldName.first.responsiblePartySignature), 
      responsiblePartyName: sheetText(pageFirst, fieldName.first.responsiblePartyName), 
      responsiblePartyAddress: sheetText(pageFirst, fieldName.first.responsiblePartyAddress), 
    },
    part1_Transfers: {
      currentQuarter: {
        totalCashDisbursements: sheetNumber(pagePart1_4, fieldName.part1.currentQuarter.totalCashDisbursements),
        nonCashSecuritiesTransferred: sheetNumber(pagePart1_4, fieldName.part1.currentQuarter.nonCashSecuritiesTransferred),
        otherNonCashPropertyTransferred: sheetNumber(pagePart1_4, fieldName.part1.currentQuarter.otherNonCashPropertyTransferred),
      },
      totalSinceEffectiveDate: {
        totalCashDisbursements: sheetNumber(pagePart1_4, fieldName.part1.totalSinceEffectiveDate.totalCashDisbursements),
        nonCashSecuritiesTransferred: sheetNumber(pagePart1_4, fieldName.part1.totalSinceEffectiveDate.nonCashSecuritiesTransferred),
        otherNonCashPropertyTransferred: sheetNumber(pagePart1_4, fieldName.part1.totalSinceEffectiveDate.otherNonCashPropertyTransferred),
      }
    },
    part2_ProfessionalFeesAndExpenses: {
      bankruptcyFirms: sheetPart2Value(part2a),
      nonBankruptcyFirms: sheetPart2Value(part2b),
      allFirm: {
        approvedCurrentQuarter: part2c.currentValue,
        approvedCumulative: part2c.cumulativeAppr,
        paidCurrentQuarter: part2c.currentPaid,
        paidCumulative: part2c.cumulativePaid,
      }
    },
    part3_RecoveriesOfClaimsAndInterests: {
      administrativeClaims: {
        allowedClaims: sheetNumber(pagePart1_4, fieldName.part3.administrativeClaims.allowedClaims),
        totalAnticipatedPayments: sheetNumber(pagePart1_4, fieldName.part3.administrativeClaims.totalAnticipatedPayments),
        paidCurrentQuarter: sheetNumber(pagePart1_4, fieldName.part3.administrativeClaims.paidCurrentQuarter),
        paidCumulative: sheetNumber(pagePart1_4, fieldName.part3.administrativeClaims.paidCumulative),
      },
      securedClaims: {
        allowedClaims: sheetNumber(pagePart1_4, fieldName.part3.securedClaims.allowedClaims),
        totalAnticipatedPayments: sheetNumber(pagePart1_4, fieldName.part3.securedClaims.totalAnticipatedPayments),
        paidCurrentQuarter: sheetNumber(pagePart1_4, fieldName.part3.securedClaims.paidCurrentQuarter),
        paidCumulative: sheetNumber(pagePart1_4, fieldName.part3.securedClaims.paidCumulative),
      },
      priorityClaims: {
        allowedClaims: sheetNumber(pagePart1_4, fieldName.part3.priorityClaims.allowedClaims),
        totalAnticipatedPayments: sheetNumber(pagePart1_4, fieldName.part3.priorityClaims.totalAnticipatedPayments),
        paidCurrentQuarter: sheetNumber(pagePart1_4, fieldName.part3.priorityClaims.paidCurrentQuarter),
        paidCumulative: sheetNumber(pagePart1_4, fieldName.part3.priorityClaims.paidCumulative),
      },
      generalUnsecuredClaims: {
        allowedClaims: sheetNumber(pagePart1_4, fieldName.part3.generalUnsecuredClaims.allowedClaims),
        totalAnticipatedPayments: sheetNumber(pagePart1_4, fieldName.part3.generalUnsecuredClaims.totalAnticipatedPayments),
        paidCurrentQuarter: sheetNumber(pagePart1_4, fieldName.part3.generalUnsecuredClaims.paidCurrentQuarter),
        paidCumulative: sheetNumber(pagePart1_4, fieldName.part3.generalUnsecuredClaims.paidCumulative),
      },
      equityInterests: {
        totalAnticipatedPayments: sheetNumber(pagePart1_4, fieldName.part3.equityInterests.totalAnticipatedPayments),
        paidCurrentQuarter: sheetNumber(pagePart1_4, fieldName.part3.equityInterests.paidCurrentQuarter),
        paidCumulative: sheetNumber(pagePart1_4, fieldName.part3.equityInterests.paidCumulative),
      }
    },
    part4_Questionnaire: {
      isThisAFinalReport: sheetBool(pagePart1_4, fieldName.part4.isThisAFinalReport),
      finalDecreeDate: sheetDate(pagePart1_4, fieldName.part4.finalDecreeDate),
      areYouCurrentWithQuarterlyUSTrusteeFees: sheetBool(pagePart1_4, fieldName.part4.areYouCurrentWithQuarterlyUSTrusteeFees),
    },
    finalSignature: {
      title: sheetText(pageFinal, fieldName.final.title),
      name: sheetText(pageFinal, fieldName.final.name),
      signature: sheetText(pageFinal, fieldName.final.signature),
      date: sheetDate(pageFinal, fieldName.final.date),
    }
  };
};

const sheetPart2Value = (val: IFeesExpensesValue[]) => {
  return val.map((r) => ({
    firmName: r.firmName,
    role: r.roleType,
    approvedCurrentQuarter: r.currentValue,
    approvedCumulative: r.cumulativeAppr,
    paidCurrentQuarter: r.currentPaid,
    paidCumulative: r.cumulativePaid,
  }));
};

const sheet2cSummaryValue = (
  part2c: IFeesExpensesValue[],
  part2a: IFeesExpensesValue[],
  part2b: IFeesExpensesValue[],
): IFeesExpensesValue => {
  if (part2c.length > 0) return part2c[0];

  const allFirms = _([...part2a, ...part2b]);

  return {
    currentValue: allFirms.map((r) => r.currentValue).sum(),
    cumulativeAppr: allFirms.map((r) => r.cumulativeAppr).sum(),
    currentPaid: allFirms.map((r) => r.currentPaid).sum(),
    cumulativePaid: allFirms.map((r) => r.cumulativePaid).sum(),
  } as IFeesExpensesValue;
};
