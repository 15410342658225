import _ from 'lodash';

/* 
Decimal separator can be '.' or ',' (Locale-dependent)
  US = 111,222.33
  EU = 111 222,33
*/
const decSeparator = (1.1).toLocaleString().charAt(1);

export const parseNumber = (param: string): number => {
  if (_.isNumber(param)) return param;
  if (!_.isString(param)) return NaN;

  const val = param.trim();

  const nonDigitsRegx = new RegExp(`[^\\d\\-\\${decSeparator}]`, 'g');
  const numberStr = val.replace(nonDigitsRegx, '').replace(decSeparator, '.');
  const result = +numberStr;

  //negative value can be presented as "(111,222)" = "-111,222"
  const isNegative = val.startsWith('(') && val.endsWith(')');
  return isNegative ? -result : result;
};
