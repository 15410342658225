import React from 'react';
import { useDropzone } from 'react-dropzone';
import {
  ApIcon,
  ApButtonMain,
  ApLoaderDefault
} from '@alixpartners/ui-components';

// styles
import './UploadFileForm.css';

interface Props {
  isLoading: boolean;
  onFileDrop: (file: File) => void;
}

export const UploadFileForm = ({ isLoading, onFileDrop }: Props) => {
  const handleDrop = (files: File[]) => {
    onFileDrop(files[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  if (isLoading) {
    return (
      <div className="upload-form">
        <div className="upload-wrapper">
          <ApLoaderDefault theme="light" loaderSize="lg" />
        </div>
      </div>
    );
  }

  return (
    <div className={`upload-form ${isDragActive ? 'dragging' : ''}`}>
      <div className="upload-wrapper" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="upload-icon">
          <ApIcon
            iconName="cloud_upload"
            iconColor="#CCCCCC"
            iconSize="120px"
          />
        </div>
        <p className="upload-tile-drag-text">
          Drag your excel template file here, or <strong>click here</strong> to
          browse for a file.
        </p>
        <div className="button-container">
          <ApButtonMain iconName="baseline_add">ADD FILE</ApButtonMain>
        </div>
      </div>
    </div>
  );
};
