import { IMorExcelObj } from '../common/excelFileTypes';
import { IEntityData, IMorEntityWorkbook } from 'types/excelTypes';
import {
  filterGetFirst,
  getfilterbyName,
  getValueByIdMapper,
  mapperOmitEntity,
} from '../common/excelUtils';

export const splitWorkbookByEntitiesMor = (
  excelObj: IMorExcelObj,
): IEntityData[] => {
  return excelObj.entities.map((entity, id) => {
    const filterByNameFn = getfilterbyName(entity.name);
    const mapperValueByIdFn = getValueByIdMapper(id);

    const workbook: IMorEntityWorkbook = {
      fields: {
        'Page 1': excelObj.fields['Page 1'].map(mapperValueByIdFn),
        'Parts 1-4': excelObj.fields['Parts 1-4'].map(mapperValueByIdFn),
        'Parts 5-8': excelObj.fields['Parts 5-8'].map(mapperValueByIdFn),
        'Responsible Party': excelObj['Responsible Party'],
      },
      feesAndExpenses: {
        'Part 5a': excelObj.feesAndExpenses['Part 5a']
          .filter(filterByNameFn)
          .map(mapperOmitEntity),
        'Part 5b': excelObj.feesAndExpenses['Part 5b']
          .filter(filterByNameFn)
          .map(mapperOmitEntity),
        'Part 5c': excelObj.feesAndExpenses['Part 5c']
          .filter(filterByNameFn)
          .filter(filterGetFirst)
          .map(mapperOmitEntity),
      },
    };

    const info = {
      title: entity.name,
      shell: entity.shell?.toLowerCase() === 'yes',
    };

    return { info, workbook } as IEntityData;
  });
};
