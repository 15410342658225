import { IExcelData, FileDataType, IEntityData } from 'types/excelTypes';

import { getPdfEngine } from 'engine/pdf/pdfEngine';

export const downloadPdfFile = async (
  entity: IEntityData,
  fileType: FileDataType
) => {
  const pdfEngine = getPdfEngine(fileType);
  const { data, error } = await pdfEngine.generatePdf(entity.workbook);
  if (error) return { error };

  const fileName = entity.info.title;
  await createFile(data, `${fileName} complete.pdf`);
};

export const downloadAllPdfFiles = async (excelData: IExcelData) => {
  const api = getPdfEngine(excelData.fileInfo.type);

  const value = excelData.entities.map((r) => r.workbook);
  const { data, error } = await api.generateAllPdf(value);
  if (error) return { error };

  const fileName = excelData.fileInfo.name.replace('.xlsx', '');
  await createFile(data, `${fileName} complete.zip`);
};

export const downloadAllFinalPdfFiles = async (excelData: IExcelData) => {
  const api = getPdfEngine(excelData.fileInfo.type);

  const value = excelData.entities.map((r) => r.workbook);
  const { data, error } = await api.generateAllPdfFinal(value);
  if (error) return { error };

  const fileName = excelData.fileInfo.name.replace('.xlsx', '');
  await createFile(data, `${fileName} final.zip`);
};

const createFile = (data: Blob, fileName: string): Promise<void> =>
  new Promise((resolve) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = fileName;

    link.href = window.URL.createObjectURL(data);

    link.click();
    resolve();
  });
