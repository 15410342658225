import { BoxSection, IBottomBarProps } from 'components/BoxSection';
import { IFileInfo } from 'types/excelTypes';
import { niceBytes } from 'utils/common/fileUtils';
import { BoxSectionRequestError } from 'components/ErrorMessage/ErrorMessage';
import { IRequestError } from 'api/requestTypes';

interface IProps {
  fileInfo: IFileInfo | undefined;
  requestError: IRequestError | undefined;
  loading?: boolean;
  onBack: () => void;
  onForward: () => void;
}

export const ExcelProsessingSection = ({
  fileInfo,
  requestError,
  onBack,
  onForward
}: IProps) => {
  const bottomBarProps: IBottomBarProps = {
    btnNext: 'Next',
    btnNextIcon: 'baseline_arrow_forward',
    //btnNextDisabled: !!requestError,
    onBack,
    onForward
  };

  const fileType = fileInfo?.type.toString().toUpperCase() ?? 'Unknown';
  const fileName = fileInfo?.name ?? 'Unknown';
  const fileSize = fileInfo?.size ?? 0;
  const niceFileSize = niceBytes(fileSize);

  const description = (
    <>
      You uploaded an <b>{fileType} Form File</b>{' '}
    </>
  );

  if (requestError)
    return (
      <BoxSectionRequestError
        bottomBar={bottomBarProps}
        requestError={requestError}
      />
    );

  return (
    <BoxSection
      title="Upload complete"
      description={description}
      bottomBar={bottomBarProps}
    >
      <div className="upload-complete processing-box">
        <>
          <div className="upload-complete_item">
            Name of file: <b>{fileName}</b>
          </div>
          <div className="upload-complete_item">
            Filesize: <b>{niceFileSize}</b>
          </div>
        </>
      </div>
    </BoxSection>
  );
};
