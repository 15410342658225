import { propsPretyName } from 'components/CheckTable';
import _ from 'lodash';
import {
  FileDataType,
  IValidationInfo,
  ValidationSheetInfo,
  FieldValueKeys,
} from 'types/excelTypes';

export const validationErrorText = (
  validationInfo: IValidationInfo,
  type: FileDataType,
) => {
  const items = Object.keys(validationInfo).map((sheetName) => {
    const errors = validationInfo[sheetName];
    const errorList = validationSheetInfoText(errors, type);

    return `${sheetName} \n${errorList.join('\n')} `;
  });

  return items.join('\n\n');
};

const validationSheetInfoText = (
  errors: ValidationSheetInfo,
  type: FileDataType,
) => {
  const prettyNameFn = propsPretyName(type);

  const result = errors.map(({ field, errorMessage }) => {
    const fileldName = _.isNumber(field) ? `Record ${field}` : field;

    const props = Object.keys(errorMessage).map((prop) => {
      const propName = prettyNameFn(prop as FieldValueKeys);
      return `    ${fileldName} : ${propName} - ${errorMessage[prop]}`;
    });
    return props.join('\n');
  });
  return result;
};
