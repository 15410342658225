import XLSX from 'xlsx';
import { FileDataType } from 'types/excelTypes';
import { parseExcelDocumentMor, splitWorkbookByEntitiesMor } from './mor';
import { parseExcelDocumentPcr, splitWorkbookByEntitiesPcr } from './pcr';

export const parseExcel = (
  excelContent: XLSX.WorkBook,
  fileType: FileDataType
) => {
  switch (fileType) {
    case FileDataType.MOR:
      return parseExcelMor(excelContent);
    case FileDataType.PCR:
      return parseExcelPcr(excelContent);
    default:
      throw new Error('Invalid data Type');
  }
};

const parseExcelMor = (excelContent: XLSX.WorkBook) => {
  const workbookObj = parseExcelDocumentMor(excelContent);
  const entities = splitWorkbookByEntitiesMor(workbookObj);
  return entities;
};

const parseExcelPcr = (excelContent: XLSX.WorkBook) => {
  const workbookObj = parseExcelDocumentPcr(excelContent);
  const entities = splitWorkbookByEntitiesPcr(workbookObj);
  return entities;
};
