import _ from 'lodash';
import { IFieldValue } from 'types/excelTypes';
import { parseNumber } from 'utils/common/numberUtils';

export const sheetText = (page: IFieldValue[], fieldName: string) =>
  sheetValue(page, fieldName, undefined);

export const sheetTextDef = (
  page: IFieldValue[],
  fieldName: string,
  defaultVal: string,
) => sheetValue(page, fieldName, getTextDef(defaultVal));

export const sheetBool = (page: IFieldValue[], fieldName: string) =>
  sheetValue(page, fieldName, getBoolean);

export const sheetBoolEnum = (page: IFieldValue[], fieldName: string) =>
  sheetValue(page, fieldName, getBooleanEnum);

export const sheetDate = (page: IFieldValue[], fieldName: string) =>
  sheetValue(page, fieldName, getDate);

export const sheetNumber = (page: IFieldValue[], fieldName: string) =>
  sheetValue(page, fieldName, getNumber);

export const sheetTextNoSpace = (page: IFieldValue[], fieldName: string) =>
  sheetValue(page, fieldName, getStringNoSpace);

export const sheetInventoryType = (page: IFieldValue[], fieldName: string) =>
  sheetValue(page, fieldName, getInventoryType);

export const sheetCaseNumber = (page: IFieldValue[], fieldName: string) =>
  sheetValue(page, fieldName, getCaseNumber);

function sheetValue(
  page: IFieldValue[],
  fieldName: string,
  converter?: (val: string) => any,
) {
  const field = page.find((r) => r.name === fieldName);
  if (_.isNil(field)) {
    console.error(`field not found - "${fieldName}"`);
    return '';
  }

  const value = field.value ?? '';
  const result = converter?.(value) ?? value;

  return result;
}

const getTextDef = (defaultVal: string) => (value: string) => {
  return value?.trim() ? value : defaultVal;
};

const getDate = (value: string) => {
  if (!value.trim()) return '';
  if (isNumeric(value)) return '';

  try {
    // GMT+0000 is added here, because for some reson date value is converted to current timezone
    const date = new Date(`${value} GMT`);
    return date.toISOString();
  } catch {
    return '';
  }
};

const getNumber = (value: string) => {
  if (value.trim() === '-') return 0;

  const num = parseNumber(value);
  if (_.isNaN(num)) return 0; //invalid value

  return num;
};

const getBoolean = (value: string) =>
  value.toLowerCase() === 'yes' ? true : false;

const getBooleanEnum = (value: string) => {
  switch (value.toLowerCase?.()) {
    case 'yes':
      return 'Yes';
    case 'no':
      return 'No';
    case 'n/a':
      return 'NA';
    default:
      return value;
  }
};

const getStringNoSpace = (value: string) => value.trim().replace(/\s/g, '');

const getInventoryType = (value: string) => {
  switch (value.toLowerCase?.()) {
    case 'book':
      return 'Book';
    case 'market':
      return 'Market';
    case 'other (attach explanation)':
    case 'other':
      return 'Other';
    default:
      return value;
  }
};

const getCaseNumber = (value: string) => value.trim().replace(/-/g, '');
const isNumeric = (value: string) => !_.isNaN(+value);
