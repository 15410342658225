import { parseNumber } from 'utils/common/numberUtils';
import XLSX from 'xlsx';
import {
  IFeesExpensesRecord,
  IParsedSheets,
  IPcrExcelObj,
} from '../common/excelFileTypes';
import {
  parseExcelWorkbook,
  getEntities,
  getEntitiesRecords,
  getSingleRecords,
  getRowsWithoutHeader_I,
} from '../common/excelUtils';

export const parseExcelDocumentPcr = (data: XLSX.WorkBook): IPcrExcelObj => {
  const parsedSheets = parseExcelWorkbook(data);

  const entities = getEntities(parsedSheets);
  const entitiesCount = entities.length;

  const result: IPcrExcelObj = {
    entities,
    fields: {
      'Page 1': getEntitiesRecords('Page 1', parsedSheets, entitiesCount),
      'Parts 1-4': getEntitiesRecords('Parts 1-4', parsedSheets, entitiesCount),
    },
    feesAndExpenses: {
      'Part 2a': getPart2Records('Part 2a', parsedSheets),
      'Part 2b': getPart2Records('Part 2b', parsedSheets),
      'Part 2c': getPart2Records('Part 2c', parsedSheets),
    },
    'Responsible Party': getSingleRecords('Responsible Party', parsedSheets),
  };

  return result;
};

const getPart2Records = (
  name: string,
  parsedSheets: IParsedSheets,
): IFeesExpensesRecord[] => {
  const sheetRows = parsedSheets[name];
  const rows = getRowsWithoutHeader_I(sheetRows);

  const result = rows.map(
    (r) =>
      ({
        firmName: r.C,
        roleType: r.D,
        currentValue: parseNumber(r.E) || 0,
        cumulativeAppr: parseNumber(r.F) || 0,
        currentPaid: parseNumber(r.G) || 0,
        cumulativePaid: parseNumber(r.H) || 0,
        entity: r.I,
      } as IFeesExpensesRecord),
  );

  return result;
};
