import { IRequestError } from 'api/requestTypes';
import { BoxSection, IBottomBarProps } from 'components/BoxSection';
import './ErrorMessage.css';

interface IProps {
  title?: string;
  text: string;
}
export const ErrorMessage = ({ title, text }: IProps) => {
  return (
    <div className="error-message">
      {title ? <div className="error-message_title">{title}</div> : null}
      <div className="error-message_text">{text}</div>
    </div>
  );
};

export const BoxSectionRequestError = (props: {
  bottomBar: IBottomBarProps;
  requestError: IRequestError;
}) => {
  return (
    <BoxSection
      title={'Error'}
      description={
        'There was an error processing your file, please fix the following and try again.'
      }
      bottomBar={props.bottomBar}
    >
      <div className="processing-box">
        <ErrorMessage
          title={`Request error: ${props.requestError.code}`}
          text={props.requestError.text}
        />
      </div>
    </BoxSection>
  );
};
