import React, { memo } from 'react';
import {
  ApSimpleTable,
  ApSimpleTableHead,
  ApSimpleTableRow,
  ApSimpleTableHeadCell,
  ApSimpleTableBody,
  ApSimpleTableBodyCell,
} from '@alixpartners/ui-components';

import './CheckTable.css';
import {
  FileDataType,
  ValidationSheetInfo,
  FieldValueKeys,
} from 'types/excelTypes';

interface IProps {
  data: object[];
  validationSheetInfo: ValidationSheetInfo;
  type: FileDataType;
}

export const CheckTable: React.FC<IProps> = memo(
  ({ data, validationSheetInfo, type }) => {
    const keys = Object.keys(data[0] || {}) as FieldValueKeys[];
    const headerList = keys.map(propsPretyName(type));

    return (
      <section className="check-table">
        <ApSimpleTable
          bodyOffset="32px"
          tableBorders={{
            bottom: true,
            left: true,
            right: true,
            top: true,
          }}
          tableHeight="400px"
          tableMinWidth="280px"
          tableWidth="100%"
        >
          <TableHeader headerList={headerList}></TableHeader>
          <ApSimpleTableBody>
            <TableDataRows
              keys={keys}
              data={data}
              validationInfo={validationSheetInfo}
            />
          </ApSimpleTableBody>
        </ApSimpleTable>
      </section>
    );
  },
);

const TableHeader = (props: { headerList: string[] }) => {
  return (
    <ApSimpleTableHead>
      <ApSimpleTableRow>
        {props.headerList.map((headerName: string, id) => (
          <ApSimpleTableHeadCell key={id.toString()} cellTextColor="#737373">
            {headerName}
          </ApSimpleTableHeadCell>
        ))}
      </ApSimpleTableRow>
    </ApSimpleTableHead>
  );
};

const TableDataRows = (props: {
  keys: string[];
  data: object[];
  validationInfo: ValidationSheetInfo;
}) => {
  const [titleKey, ...valueKeys] = props.keys;
  return (
    <>
      {props.data.map((item: any, index: number) => {
        const fileldName = item[titleKey];

        const error = props.validationInfo?.find(
          (r) => r.field === index || r.field === fileldName,
        )?.errorMessage;

        const className = error ? 'missing-cell' : '';

        return (
          <ApSimpleTableRow key={index}>
            <ApSimpleTableBodyCell className={className}>
              <div>{fileldName}</div>
              <ErrorText text={error?.[titleKey]} />
            </ApSimpleTableBodyCell>

            {valueKeys.map((key) => {
              const value = item[key];
              return (
                <ApSimpleTableBodyCell className={className} key={key}>
                  <div>{value}</div>
                  <ErrorText text={error?.[key]} />
                </ApSimpleTableBodyCell>
              );
            })}
          </ApSimpleTableRow>
        );
      })}
    </>
  );
};

const ErrorText: React.FC<{ text: string | undefined }> = ({ text }) =>
  text ? <div className="check-table_error-text">{text}</div> : null;

export const propsPretyName = (type: FileDataType) => (key: FieldValueKeys) => {
  switch (key) {
    case 'name':
      return 'Name';
    case 'value':
      return 'Value';

    case 'firmName':
      return 'Firm Name';
    case 'roleType':
      return 'Role Type';

    case 'cumulativeAppr':
      return 'Approved Cumulative';
    case 'cumulativePaid':
      return 'Paid Cumulative';

    case 'currentValue':
      return type === FileDataType.PCR
        ? 'Approved Current Quartal'
        : 'Accumulated Current Month';
    case 'currentPaid':
      return type === FileDataType.PCR
        ? 'Paid Current Quartal'
        : 'Paid Current Month';

    default:
      return key;
  }
};
