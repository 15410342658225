import { getPdfEngine } from 'engine/pdf/pdfEngine';
import { FileDataType, IEntityData } from 'types/excelTypes';

export const validateExcelData = async (
  entities: IEntityData[],
  fileType: FileDataType
) => {
  const engine = getPdfEngine(fileType);
  const value = entities.map((r) => r.workbook);
  const requestResult = await engine.validateData(value);

  const validationData = requestResult.data;

  entities.forEach((ent, id) => {
    const entityValidation = validationData.find((e) => e.entityId === id);

    ent.info.errorCount = entityValidation?.count ?? 0;
    ent.validationInfo = entityValidation?.validationInfo ?? {};
  });

  return { entities, error: requestResult.error };
};
