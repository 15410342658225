import { useCallback, useState } from 'react';

export const useTimerProgress = (
  minValue: number = 0,
  maxValue: number = 100
) => {
  const [progress, setProgress] = useState(minValue);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();

  const stopTimer = useCallback(() => {
    if (timerId) clearInterval(timerId);
    setTimerId(undefined);
  }, [timerId, setTimerId]);

  const startTimer = useCallback(
    (totalTimeMs: number) => {
      if (timerId) stopTimer();
      const interval = Math.trunc(totalTimeMs / 100);
      setProgress(minValue);

      const increaseProgressFn = () =>
        setProgress((value) => {
          if (value >= maxValue) stopTimer();
          return value < maxValue ? value + 1 : maxValue;
        });

      const id = setInterval(increaseProgressFn, interval);
      setTimerId(id);
    },
    [timerId, minValue, maxValue, setProgress, setTimerId, stopTimer]
  );

  return { progress, startTimer, stopTimer };
};
