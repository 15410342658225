import { Api } from 'api/endpoints';
import {
  IRequestError,
  IRequestResult,
  IValidationError,
  IValidationResult,
} from 'api/requestTypes';
import _ from 'lodash';

import {
  FileDataType,
  IMorEntityWorkbook,
  IPcrEntityWorkbook,
  IValidationInfo,
  IWorkbookFields,
} from 'types/excelTypes';
import { mappingMor } from './mapper/mappingMor';
import { mappingPcr } from './mapper/mappingPcr';
import { IMemberSheetFieled } from './mapper/mappingTypes';
import { memberConverterMor } from './mapper/mapStructureMor';
import { memberConverterPcr } from './mapper/mapStructurePcr';

export interface IPdfEngine {
  generatePdf: (data: IWorkbookFields) => Promise<IRequestResult>;
  generateAllPdf: (data: IWorkbookFields[]) => Promise<IRequestResult>;
  generateAllPdfFinal: (data: IWorkbookFields[]) => Promise<IRequestResult>;
  validateData: (data: IWorkbookFields[]) => Promise<IValidateDataResult>;
}

interface IValidationEntity {
  entityId: number;
  count: number;
  validationInfo: IValidationInfo;
}

interface IValidateDataResult {
  data: IValidationEntity[];
  error?: IRequestError;
}

export const getPdfEngine = (fileType: FileDataType): IPdfEngine => {
  if (fileType === FileDataType.MOR) return ApiMor;
  if (fileType === FileDataType.PCR) return ApiPcr;

  throw new Error('Invalid data Type');
};

const ApiMor: IPdfEngine = {
  generatePdf: (data: IWorkbookFields) => {
    const value = mappingMor(data as IMorEntityWorkbook);
    return Api.generateMOR(value);
  },
  generateAllPdf: (data: IWorkbookFields[]) => {
    const value = data.map((r) => mappingMor(r as IMorEntityWorkbook));
    return Api.generateAllMOR(value);
  },
  generateAllPdfFinal: (data: IWorkbookFields[]) => {
    const value = data.map((r) => mappingMor(r as IMorEntityWorkbook));
    return Api.generateAllMORFinal(value);
  },
  validateData: async (data: IWorkbookFields[]) => {
    const value = data.map((r) => mappingMor(r as IMorEntityWorkbook));
    const response = await Api.validateMORs(value);

    const validationResult: IValidationResult[] = response?.data ?? [];

    const result = validationResult.map((r) => ({
      entityId: r.entityId,
      count: r.errors.length,
      validationInfo: toValidationInfo(r.errors, memberConverterMor),
    }));

    return { data: result, error: response.error };
  },
};

const ApiPcr: IPdfEngine = {
  generatePdf: (data: IWorkbookFields) => {
    const value = mappingPcr(data as IPcrEntityWorkbook);
    return Api.generatePCR(value);
  },
  generateAllPdf: (data: IWorkbookFields[]) => {
    const value = data.map((r) => mappingPcr(r as IPcrEntityWorkbook));
    return Api.generateAllPCR(value);
  },
  generateAllPdfFinal: (data: IWorkbookFields[]) => {
    const value = data.map((r) => mappingPcr(r as IPcrEntityWorkbook));
    return Api.generateAllPCRFinal(value);
  },
  validateData: async (data: IWorkbookFields[]) => {
    const value = data.map((r) => mappingPcr(r as IPcrEntityWorkbook));
    const response = await Api.validatePCRs(value);

    const validationResult: IValidationResult[] = response?.data ?? [];

    const result = validationResult.map((r) => ({
      entityId: r.entityId,
      count: r.errors.length,
      validationInfo: toValidationInfo(r.errors, memberConverterPcr),
    }));

    return { data: result, error: response?.error };
  },
};

function toValidationInfo(
  errors: IValidationError[],
  memberConventer: (memberNames: string[]) => IMemberSheetFieled,
): IValidationInfo {
  const errorMembers = errors.map((ve) => ({
    errorMessage: ve.errorMessage,
    ...memberConventer(ve.memberNames),
  }));

  // convert array to object grouped by Sheet
  const result = _(errorMembers)
    .groupBy((m) => m.sheet)
    .mapValues((groupVal) =>
      groupVal.map((val) => ({
        field: val.recordId,
        errorMessage: { [val.field]: val.errorMessage },
      })),
    )
    .value();

  return result;
}
