import { ApModal } from '@alixpartners/ui-components';
import { trackEvent } from 'utils/common/telemetry';
import { IRequestError } from 'api/requestTypes';
import { BoxSection, IBottomBarProps } from 'components/BoxSection';
import { EntityItem, EntityList } from 'components/EntityList';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { IEntityData } from 'types/excelTypes';

const descriptionText =
  'You can preview the entities individually or download them all as a zip. Please note that in order to view editable PDFs you must use Adobe Acrobat Pro';

interface Props {
  data: IEntityData[] | undefined;
  loadingAll: boolean;
  entityLoading: boolean[];
  requestError: IRequestError | undefined;
  onCloseErrorDialog: () => void;
  onValidateClick: (id: string) => void;
  onDowndloadClick: (id: string) => void;
  onDownloadAllClick: () => void;
  onDownloadFinalAllClick: () => void;
  onBack: () => void;
}

export const EntitiesListSection = ({
  data = [],
  loadingAll,
  entityLoading,
  requestError,
  onCloseErrorDialog,
  onValidateClick,
  onDowndloadClick,
  onDownloadAllClick,
  onDownloadFinalAllClick,
  onBack,
}: Props) => {
  //const infoItems = data?.map((r) => r.info) ?? [];
  const title = `${data?.length} Entities Found`;
  const loaderText = (
    <div className="upload-entities_loadingText">
      Processing, please wait. This might take several minutes
    </div>
  );

  const bottomBarProps: IBottomBarProps = {
    btnNext: 'Download All - Final',
    btnNextIcon: 'outline_check_circle_outline',
    btnNextDisabled: !!requestError,
    onBack,
    onForward: onDownloadFinalAllClick,
  };

  trackEvent('Entity List Size', { listLength: data.length });

  return (
    <>
      <BoxSection
        title="Preview forms"
        description={descriptionText}
        bottomBar={bottomBarProps}
      >
        <div className="upload-entities">
          <EntityList
            title={title}
            loading={loadingAll}
            loaderText={loaderText}
            onDownloadAllClick={onDownloadAllClick}
          >
            {data?.map(({ info }, id) => {
              return (
                <EntityItem
                  key={id}
                  id={`${id}`}
                  name={info.title}
                  isShell={info.shell}
                  isLoading={entityLoading[id]}
                  errorCount={info.errorCount ?? 0}
                  onValidateClick={onValidateClick}
                  onDownloadClick={onDowndloadClick}
                />
              );
            })}
          </EntityList>
        </div>
      </BoxSection>

      {requestError ? (
        <ApModal
          className="message-dialog"
          hasClosed={onCloseErrorDialog}
          header="Error"
          isDismissible={true}
          isOpen={!!requestError}
          isFluid
        >
          <div>
            <ErrorMessage
              title={`Request Error : ${requestError.code}`}
              text={requestError.text}
            />
          </div>
        </ApModal>
      ) : null}
    </>
  );
};
