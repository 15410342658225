import { CSSProperties } from 'react';

import './ProgressBar.css';

interface IProps {
  caption?: React.ReactNode;
  value: number;
  color?: string;
  height?: number;
}

export const ProgressBar = ({
  caption,
  value,
  color = '#0696A6',
  height = 4
}: IProps) => {
  const progress = value > 100 ? 100 : value;

  const containerStyles: CSSProperties = {
    height
  };

  const fillerStyles: CSSProperties = {
    width: `${progress}%`,
    backgroundColor: color
  };

  return (
    <div className="progress-bar_body">
      {caption ? <div className="progress-bar_title"> {caption}</div> : null}
      <div className="progress-bar_container" style={containerStyles}>
        <div className="progress-bar_filler" style={fillerStyles} />
      </div>
    </div>
  );
};

export default ProgressBar;
