import _ from 'lodash';
import { pagePcr } from 'types/excelTypes';
import { toCamelCase } from 'utils/common/dataUtils';
import { IMemberSheetFieled } from './mappingTypes';

export const memberConverterPcr = (
  memberNames: string[],
): IMemberSheetFieled => {
  const members = toCamelCase(memberNames);
  const result =
    getMemberSheetForAdditionaData(members) ?? getMemberSheetField(members);
  return result;
};

/**
 * Convert back-end members list for section 2a and 2b - into structure with Excel names
 *
 * For example members:
 *   ["Part2_ProfessionalFeesAndExpenses", "BankruptcyFirms", "3", "FirmName"]
 * will return next structure
 *   {
 *     sheet: 'Part 2a',
 *     recordId: '3',
 *     field: 'FirmName'
 *   }
 */
const getMemberSheetForAdditionaData = (
  members: string[],
): IMemberSheetFieled | undefined => {
  //"Page 2a" and "Page 2b" two sheets contains tables with additional data
  const [sheet, section, recordId, subField] = members;
  if (sheet !== additionalDataPart) return undefined;

  let sheetName = _.get(mapPcrAdditionalNames, section) as string;
  if (!sheetName) {
    console.error(`${section} field not found; \n members = "${members}"`);
    sheetName = section;
  }

  return {
    sheet: sheetName,
    recordId: +recordId,
    field: subField,
  };
};

/**
 * Convert back-end members list - into structure with Excel names
 *
 * For example - members:["FirstPage", "CaseNo"]
 * will return next structure
 *   {
 *     sheet: 'Page 1',
 *     recordId: 'Case No.',
 *     field: 'value'
 *   }
 */
const getMemberSheetField = (members: string[]): IMemberSheetFieled => {
  const [sheet, recordNameDef] = members;

  let sheetName = _.get(mapPcrPagesNames, sheet) as string;
  let recordName = _.get(mapPcrStruct, members) as string;

  if (!sheetName) {
    console.error(`${sheet} field not found; \n members = "${members}"`);
    sheetName = sheet;
  }

  if (!recordName) {
    console.error(`${recordName} field not found; \n members = "${members}"`);
    recordName = recordNameDef;
  }

  return {
    sheet: sheetName,
    recordId: recordName,
    field: 'value',
  };
};

export const additionalDataPart = 'part2_ProfessionalFeesAndExpenses';

const mapPcrPagesNames = {
  firstPage: pagePcr.firstPage,
  part1_Transfers: pagePcr.parts1_4,
  part3_RecoveriesOfClaimsAndInterests: pagePcr.parts1_4,
  part4_Questionnaire: pagePcr.parts1_4,
  final_Signatures: pagePcr.finalPage,
};

const mapPcrAdditionalNames = {
  bankruptcyFirms: pagePcr.part_2a,
  nonBankruptcyFirms: pagePcr.part_2b,
  allFirm: pagePcr.part_2c,
};

// this object is used in validation.
// keep structure same as in the request
export const mapPcrStruct = {
  firstPage: {
    districtQualifier: 'District Qualifier',
    districtState: 'District State',
    courtDivision: 'Court Division',
    debtors: 'Debtor(s)',
    caseNo: 'Case No.',
    leadCaseNo: 'Lead Case No.',
    isJointlyAdministered: 'Jointly Administered',
    reportType: 'Report Type',
    quarterEndingDate: 'Quarter Ending Date',
    petitionDate: 'Petition Date',
    planConfirmedDate: 'Plan Confirmed Date',
    planEffectiveDate: 'Plan Effective Date',
    reportingParty: 'This Post-confirmation Report relates to',
    otherPartyName: 'Other Authorized Party or Entity - Name',
    signatureDate: 'Date',
    responsiblePartySignature: 'Signature of Responsible Party',
    responsiblePartyName: 'Printed Name of Responsible Party',
    responsiblePartyAddress: 'Address',
  },
  part1_Transfers: {
    currentQuarter: {
      totalCashDisbursements: 'Current Quarter - Total cash disbursements',
      nonCashSecuritiesTransferred:
        'Current Quarter - Non-cash securities transferred',
      otherNonCashPropertyTransferred:
        'Current Quarter - Other non-cash property transferred',
    },
    totalSinceEffectiveDate: {
      totalCashDisbursements: 'Total - Total cash disbursements',
      nonCashSecuritiesTransferred: 'Total - Non-cash securities transferred',
      otherNonCashPropertyTransferred:
        'Total - Other non-cash property transferred',
    },
  },
  // part2_ProfessionalFeesAndExpenses: {} // arrays with additional data.
  part3_RecoveriesOfClaimsAndInterests: {
    administrativeClaims: {
      totalAnticipatedPayments: 'Administrative claims - Total Payments',
      paidCurrentQuarter: 'Administrative claims - Paid Current Quarter',
      paidCumulative: 'Administrative claims - Paid Cumulative',
      allowedClaims: 'Administrative claims - Allowed Claims',
    },
    securedClaims: {
      totalAnticipatedPayments: 'Secured claims - Total Payments',
      paidCurrentQuarter: 'Secured claims - Paid Current Quarter',
      paidCumulative: 'Secured claims - Paid Cumulative',
      allowedClaims: 'Secured claims - Allowed Claims',
    },
    priorityClaims: {
      totalAnticipatedPayments: 'Priority claims - Total Payments',
      paidCurrentQuarter: 'Priority claims - Paid Current Quarter',
      paidCumulative: 'Priority claims - Paid Cumulative',
      allowedClaims: 'Priority claims - Allowed Claims',
    },
    generalUnsecuredClaims: {
      totalAnticipatedPayments: 'General unsecured claims - Total Payments',
      paidCurrentQuarter: 'General unsecured claims - Paid Current Quarter',
      paidCumulative: 'General unsecured claims - Paid Cumulative',
      allowedClaims: 'General unsecured claims - Allowed Claims',
    },
    equityInterests: {
      totalAnticipatedPayments: 'Equity interests - Total Payments',
      paidCurrentQuarter: 'Equity interests - Paid Current Quarter',
      paidCumulative: 'Equity interests - Paid Cumulative',
    },
  },
  part4_Questionnaire: {
    isThisAFinalReport: 'Is this a final report?',
    finalDecreeDate: 'Final Decree Date',
    areYouCurrentWithQuarterlyUSTrusteeFees:
      'Are you current with quarterly U.S. Trustee fees as set forth under 28 U.S.C. § 1930?',
  },
  finalSignature: {
    title: 'Title',
    name: 'Printed Name of ResponsibleParty',
    signature: 'Signature of Responsible Party',
    date: 'Date',
  },
};
