import React from 'react';
import {
  ApButtonIcon,
  ApIcon,
  ApLoaderDefault,
} from '@alixpartners/ui-components';
import { Icon } from 'components/Icon';

type IconProps = Omit<React.ComponentProps<typeof Icon>, 'name'>;
type ApIconProps = React.ComponentProps<typeof ApIcon>;
type ApLoaderDefaultProps = React.ComponentProps<typeof ApLoaderDefault>;
type ApButtonIconProps = Omit<
  React.ComponentProps<typeof ApButtonIcon>,
  'iconName'
>;

export const IconShell: React.FC<IconProps> = (props) => (
  <Icon
    {...props}
    className="entity-listitem_shell"
    name="shell"
    fill="#15723B"
  />
);

export const IconCheck: React.FC<ApIconProps> = (props) => (
  <ApIcon {...props} iconName="check" />
);

export const IconValidationError: React.FC<ApIconProps> = (props) => (
  <ApIcon {...props} iconName="error_outline" iconColor="#BC1310" />
);

export const IconLoading: React.FC<ApLoaderDefaultProps> = (props) => (
  <ApLoaderDefault {...props} theme="light" loaderSize="sm" />
);

export const IconBtnDownload: React.FC<ApButtonIconProps> = (props) => (
  <ApButtonIcon {...props} autoFocus={false} iconName={'download'} />
);
