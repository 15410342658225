import {
  ApButtonSecondary,
  ApLoaderDefault,
} from '@alixpartners/ui-components';
import { ReactElement } from 'react';
import { EntityItem } from './EntityItem';

import './EntityList.css';

interface Props {
  title: string;
  loading: boolean;
  loaderText?: React.ReactNode;
  children: ReactElement<typeof EntityItem, any>[];
  onDownloadAllClick?: () => void;
}

export const EntityList = ({
  title,
  loading,
  loaderText,
  children,
  onDownloadAllClick,
}: Props) => {
  return (
    <div className="entity-list">
      {loading ? (
        <ApLoaderDefault
          className="entity-list_loader"
          theme="light"
          loaderSize="lg"
          loaderText={() => loaderText}
        />
      ) : null}

      <EntityListHeaderBar
        title={title}
        loading={loading}
        onDownloadClick={onDownloadAllClick}
      />

      <div className="entity-list_items">{children}</div>
    </div>
  );
};

const EntityListHeaderBar = (props: {
  title: string;
  loading: boolean;
  onDownloadClick?: () => void;
}) => {
  return (
    <div className="entity-list_top">
      <div className="entity-list_title"> {props.title} </div>
      <div className="entity-list_space" />
      <ApButtonSecondary
        className="entity-list_btn-downloadall"
        autoFocus={false}
        disabled={props.loading}
        iconName="save_alt"
        onClick={props.onDownloadClick}
      >
        DOWNLOAD ALL - EDITABLE
      </ApButtonSecondary>
    </div>
  );
};
