import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { ApAuthenticationContextProvider } from '@alixpartners/ui-utils';
import { ApModal } from '@alixpartners/ui-components';
import { NavBar } from './components/NavBar';

// Analytics
import { setupAppInsights } from './utils/common/telemetry';

// Pages
import { Help } from './components/Help';
import ErrorPage from './views/Error';
import { MainPage } from 'views/MainPage/MainPage';

import './styles/App.css';
import './styles/pages.css';

setupAppInsights(process.env.REACT_APP_INSIGHT_KEY);

/**
 * App
 */
function App() {
  const [help, showingHelp] = useState(false);

  return (
    <div className="App">
      <ApAuthenticationContextProvider
        errorComponent={({ errorType, errorDescription, onClick }) => (
          <ErrorPage
            title={errorType}
            description={errorDescription}
            onClick={onClick}
          />
        )}
      >
        <BrowserRouter>
          <NavBar showhelp={() => showingHelp(true)} />

          <main className="main">
            <Routes>
              <Route path="/main" element={<MainPage />} />
              <Route path="/signin" element={<Navigate to="/main" />} />
              <Route path="/accessdenied" element={<div>Access Denied</div>} />

              <Route path="/" element={<Navigate to="/main" />} />
            </Routes>
          </main>

          {help && (
            <ApModal
              header="Using the Chapter 11 Automated Form Tool"
              isDismissible={true}
              isFluid={false}
              isOpen
              zIndex={998}
              hasClosed={() => showingHelp(false)}
            >
              <Help />
            </ApModal>
          )}
        </BrowserRouter>
      </ApAuthenticationContextProvider>
    </div>
  );
}

export default App;
