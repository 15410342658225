import { IPcrExcelObj } from '../common/excelFileTypes';
import { IEntityData, IPcrEntityWorkbook } from 'types/excelTypes';
import {
  filterGetFirst,
  getfilterbyName,
  getValueByIdMapper,
  mapperOmitEntity,
} from '../common/excelUtils';

export const splitWorkbookByEntitiesPcr = (
  excelObj: IPcrExcelObj,
): IEntityData[] => {
  return excelObj.entities.map((entity, id) => {
    const filterByNameFn = getfilterbyName(entity.name);
    const mapperValueByIdFn = getValueByIdMapper(id);

    const workbook = {
      fields: {
        'Page 1': excelObj.fields['Page 1'].map(mapperValueByIdFn),
        'Parts 1-4': excelObj.fields['Parts 1-4'].map(mapperValueByIdFn),
        'Responsible Party': excelObj['Responsible Party'],
      },
      feesAndExpenses: {
        'Part 2a': excelObj.feesAndExpenses['Part 2a']
          .filter(filterByNameFn)
          .map(mapperOmitEntity),
        'Part 2b': excelObj.feesAndExpenses['Part 2b']
          .filter(filterByNameFn)
          .map(mapperOmitEntity),
        'Part 2c': excelObj.feesAndExpenses['Part 2c']
          .filter(filterByNameFn)
          .filter(filterGetFirst)
          .map(mapperOmitEntity),
      },
    } as IPcrEntityWorkbook;

    const info = {
      title: entity.name,
      shell: entity.shell?.toLowerCase() === 'yes',
    };

    return { info, workbook } as IEntityData;
  });
};
