import _ from 'lodash';
import { IValidationError, IValidationResult } from '../requestTypes';

export const getError = (stream: Response) => ({
  code: stream.status,
  text: stream.statusText
});

export const getError400 = (error: any) => {
  const text =
    tryGetSystemErrorText(error) ||
    tryGetValidationErrorText(error) ||
    tryGetValidationMultipleErrorText(error) ||
    'Unexpected request error';
  return { code: 400, text };
};

const tryGetSystemErrorText = (error: any): string | undefined => {
  /*  ======== sample of the error message ======
      { 
        errors: {[1].firstPage.reportType: Array(1)}
        status: 400
        title: 'One or more validation errors occurred.'
        traceId: '00-61c3c66fa50ab044ad19ba9e4cd66db6-0dc10576a3ae694a-00'
        type: 'https://tools.ietf.org/html/rfc7231#section-6.5.1'
      }
    */

  if (!error?.traceId || !error?.errors) return;
  const text = Object.values(error.errors).join('\n');

  return `${error.title} \n ${text}`;
};

const tryGetValidationErrorText = (error: IValidationError[]) => {
  if (
    !_.isArray(error) ||
    _.isNil(error[0].memberNames) ||
    _.isNil(error[0].errorMessage)
  )
    return;

  const errorsList = error.map(
    (e) => e.memberNames.join('->') + ': ' + e.errorMessage
  );

  return errorsList.join('\n');
};

const tryGetValidationMultipleErrorText = (error: IValidationResult[]) => {
  if (
    !_.isArray(error) ||
    _.isNil(error[0].entityId) ||
    _.isNil(error[0].errors)
  )
    return;

  const errorsList = error.map((item) => {
    const text = item.errors
      .map((e) => '    ' + e.memberNames.join('->') + ': ' + e.errorMessage)
      .join('\n');

    return `Entity item: ${item.entityId + 1} \n${text}`;
  });

  return errorsList.join('\n\n');
};
