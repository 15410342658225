export enum FileDataType {
  Unknwown = 'Unknown',
  MOR = 'MOR',
  PCR = 'PCR',
}

export type FieldValueKeys = keyof IFeesExpensesValue | keyof IFieldValue;

export interface IExcelData {
  fileInfo: IFileInfo;
  entities: IEntityData[];
}

export interface IEntityData {
  info: IEntityInfo;
  workbook: IWorkbookFields;
  validationInfo: IValidationInfo;
}

export interface IValidationInfo {
  [sheet: string]: ValidationSheetInfo;
}

export type ValidationSheetInfo = {
  field: string | number;
  errorMessage: {
    [props: string]: string;
  };
}[];

export interface IEntityInfo {
  title: string;
  shell: boolean;
  errorCount: number;
}

export enum pageMor {
  firstPage = 'Page 1',
  parts1_4 = 'Parts 1-4',
  parts5_8 = 'Parts 5-8',
  finalPage = 'Responsible Party',
  part_5a = 'Part 5a',
  part_5b = 'Part 5b',
  part_5c = 'Part 5c',
}

export enum pagePcr {
  firstPage = 'Page 1',
  parts1_4 = 'Parts 1-4',
  finalPage = 'Responsible Party',
  part_2a = 'Part 2a',
  part_2b = 'Part 2b',
  part_2c = 'Part 2c',
}

export interface IMorEntityWorkbook {
  fields: {
    'Page 1': IFieldValue[];
    'Parts 1-4': IFieldValue[];
    'Parts 5-8': IFieldValue[];
    'Responsible Party': IFieldValue[];
  };
  feesAndExpenses: {
    'Part 5a': IFeesExpensesValue[];
    'Part 5b': IFeesExpensesValue[];
    'Part 5c': IFeesExpensesValue[];
  };
}

export interface IPcrEntityWorkbook {
  fields: {
    'Page 1': IFieldValue[];
    'Parts 1-4': IFieldValue[];
    'Responsible Party': IFieldValue[];
  };
  feesAndExpenses: {
    'Part 2a': IFeesExpensesValue[];
    'Part 2b': IFeesExpensesValue[];
    'Part 2c': IFeesExpensesValue[];
  };
}

export type IWorkbookFields = IMorEntityWorkbook | IPcrEntityWorkbook;

export interface IFileInfo {
  name: string;
  size: number;
  type: FileDataType;
}

export interface IFeesExpensesValue {
  firmName: string;
  roleType: string;
  currentValue: number;
  cumulativeAppr: number;
  currentPaid: number;
  cumulativePaid: number;
}

export interface IFieldValue {
  name: string;
  value: string;
}
