import _ from 'lodash';
import {
  IEntitiesRecord,
  IFeesExpensesRecord,
  IFieldRecords,
  IParsedSheets,
  IRowObject,
} from './excelFileTypes';
import { IFeesExpensesValue, IFieldValue } from 'types/excelTypes';
import XLSX from 'xlsx';

const headerValueColumn = 'Field';
const headerEntityColumn = 'Entity';
const defaultEntitiesValue: IEntitiesRecord[] = [
  { name: 'Entity', shell: 'NO' },
];

const isBlankValue = (val: any) => _.isNil(val) || val === '';

export const getValueByIdMapper = (id: number) => (field: IFieldRecords) => {
  const { name, master, values } = field;
  const isEmpty = isBlankValue(values[id]);
  const value = !isEmpty ? values[id] : master;
  return { name, value };
};

export const mapperOmitEntity = (
  value: IFeesExpensesRecord,
): IFeesExpensesValue => {
  return _.omit(value, 'entity');
};

export const getfilterbyName = (name: string) => {
  const nameLower = name.toLowerCase();

  const filterFn = <T extends { entity: string }>(r: T) =>
    r.entity.toLowerCase() === nameLower;

  return filterFn;
};

export const filterGetFirst = <T>(r: T, index: number) => index === 0;

export const parseExcelWorkbook = (data: XLSX.WorkBook) =>
  data.SheetNames.reduce((result: IParsedSheets, sheetName: string) => {
    const sheet = data.Sheets[sheetName];
    const value = XLSX.utils.sheet_to_json(sheet, {
      header: 'A',
      raw: false,
    });
    return { ...result, [sheetName]: value } as IParsedSheets;
  }, {});

export const getEntities = (sheets: IParsedSheets): IEntitiesRecord[] => {
  const sheetRows = sheets['Set-Up'];
  if (!sheetRows) return defaultEntitiesValue;

  const rows = getRowsWithoutHeader(sheetRows, headerEntityColumn);
  if (rows.length <= 0) return defaultEntitiesValue;

  return rows.map((r) => ({
    name: r.B,
    shell: r.E,
  }));
};

export const getRowsWithoutHeader = (
  rows: IRowObject[],
  headerText: string,
): IRowObject[] => {
  if (!rows) return [];

  return rows
    .filter((row) => row.B && row.B !== '')
    .filter((row) => row.B !== headerText);
};

export const getRowsWithoutHeader_I = (rows: IRowObject[]): IRowObject[] => {
  if (!rows) return [];

  return rows
    .filter((row) => row.I && row.I !== '')
    .filter((row) => row.I !== headerEntityColumn);
};

export const getEntitiesRecords = (
  name: string,
  parsedSheets: IParsedSheets,
  entitiesCount: number,
): IFieldRecords[] => {
  const sheetRows = parsedSheets[name];
  const isMaster = getIsMasterUsed(sheetRows);
  const rows = getRowsWithoutHeader(sheetRows, headerValueColumn);

  return rows.map((r) => {
    const name = r.B;
    const { master, values } = getEntitiesArray(r, entitiesCount, isMaster);
    return { name, master, values };
  });
};

export const getSingleRecords = (
  name: string,
  parsedSheets: IParsedSheets,
): IFieldValue[] => {
  const sheetRows = parsedSheets[name];
  const rows = getRowsWithoutHeader(sheetRows, headerValueColumn);

  return rows.map(
    (r) =>
      ({
        name: r.B,
        value: r.C,
      } as IFieldValue),
  );
};

export const getIsMasterUsed = (rows: IRowObject[]) => {
  const headerRow = rows.find((row) => row.B === headerValueColumn);
  if (!headerRow) return false;

  return headerRow.C.toLowerCase().startsWith('master');
};

const getEntitiesArray = (
  row: IRowObject,
  count: number,
  isUsedMasterColumn: boolean,
): { master: string; values: string[] } => {
  // if master column exists - then entities starts from column D, otherwise from column C
  const startFrom = isUsedMasterColumn ? 4 : 3;
  const columnsNames = _.range(count).map((r) =>
    excelColumnName(startFrom + r),
  );

  const values = columnsNames.map((r) => row[r]);
  const master = isUsedMasterColumn ? row.C : '';

  return { master, values };
};

function excelColumnName(num: number) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  while (num > 0) {
    const id = (num - 1) % 26;
    result = chars[id] + result;
    num = Math.trunc((num - 1) / 26);
  }
  return result;
}
