import XLSX from 'xlsx';
import {
  IParsedSheets,
  IMorExcelObj,
  IFeesExpensesRecord,
} from '../common/excelFileTypes';
import {
  getEntities,
  getEntitiesRecords,
  getRowsWithoutHeader_I,
  getSingleRecords,
} from '../common/excelUtils';

import { parseNumber } from 'utils/common/numberUtils';

export const parseExcelDocumentMor = (data: XLSX.WorkBook): IMorExcelObj => {
  const parsedSheets = data.SheetNames.reduce(
    (result: IParsedSheets, sheetName: string) => {
      const sheet = data.Sheets[sheetName];
      const value = XLSX.utils.sheet_to_json(sheet, {
        header: 'A',
        raw: false,
      });
      return { ...result, [sheetName]: value } as IParsedSheets;
    },
    {},
  );

  const entities = getEntities(parsedSheets);
  const entitiesCount = entities.length;

  const result: IMorExcelObj = {
    entities,
    fields: {
      'Page 1': getEntitiesRecords('Page 1', parsedSheets, entitiesCount),
      'Parts 1-4': getEntitiesRecords('Parts 1-4', parsedSheets, entitiesCount),
      'Parts 5-8': getEntitiesRecords('Parts 5-8', parsedSheets, entitiesCount),
    },
    feesAndExpenses: {
      'Part 5a': getPage5Table('Part 5a', parsedSheets),
      'Part 5b': getPage5Table('Part 5b', parsedSheets),
      'Part 5c': getPage5Table('Part 5c', parsedSheets),
    },
    'Responsible Party': getSingleRecords('Responsible Party', parsedSheets),
  };

  return result;
};

const getPage5Table = (
  name: string,
  parsedSheets: IParsedSheets,
): IFeesExpensesRecord[] => {
  const sheetRows = parsedSheets[name];
  const rows = getRowsWithoutHeader_I(sheetRows);

  const result = rows.map(
    (r) =>
      ({
        firmName: r.C,
        roleType: r.D,
        currentValue: parseNumber(r.E) || 0,
        cumulativeAppr: parseNumber(r.F) || 0,
        currentPaid: parseNumber(r.G) || 0,
        cumulativePaid: parseNumber(r.H) || 0,
        entity: r.I,
      } as IFeesExpensesRecord),
  );

  return result;
};
