import {
  IconBtnDownload,
  IconCheck,
  IconLoading,
  IconShell,
  IconValidationError,
} from 'components/IconDefault';

import './EntityItem.css';

export interface EntityProps {
  id: string;
  name: string;
  isShell?: boolean;
  isLoading: boolean;
  errorCount: number;
  onValidateClick?: (id: string) => void;
  onDownloadClick?: (id: string) => void;
}

export const EntityItem = ({
  id,
  name,
  isShell,
  isLoading,
  errorCount,
  onValidateClick,
  onDownloadClick,
}: EntityProps) => {
  const handleValidateClick = () => onValidateClick?.(id);
  const handleDownloadClick = () => onDownloadClick?.(id);

  const hasErrors = errorCount > 0;
  const validation = hasErrors ? `${errorCount} Errors` : 'Passed validation';
  const itemClassName = `entity-listitem ${isShell ? 'entity-shell' : ''}`;

  return (
    <div className={itemClassName}>
      {hasErrors ? <IconValidationError /> : <IconCheck />}
      <div className="entity-listitem_name" onClick={handleValidateClick}>
        {name}
      </div>
      <div className="entity-listitem_validation">({validation})</div>
      {isShell ? <IconShell /> : null}
      <div className="entity-listitem_space" />
      <div className="entity-listitem_loadinglabel">
        {isLoading ? 'Processing ...' : 'Download - Editable'}
      </div>
      <div className="entity-listitem_download">
        {isLoading ? (
          <IconLoading />
        ) : (
          <IconBtnDownload onClick={handleDownloadClick} />
        )}
      </div>
    </div>
  );
};
