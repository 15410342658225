import React from 'react';
import { useLocation } from 'react-router';
import { ApAuthenticationContextConsumer } from '@alixpartners/ui-utils';

import {
  ApHeaderBar,
  colors,
  ApMenuItem,
  ApMenuUser,
  ApHeaderBarDivider,
  ApButtonTab,
  ApButtonIcon,
} from '@alixpartners/ui-components';

// styles
import './NavBar.css';

interface INavBarProps {
  user?: any;
  logout?(): void;
  showhelp?(): void;
}

const getInitials = (fullName: string) => {
  return fullName
    .split(' ')
    .map((n) => n[0])
    .join('');
};

const TABS = [{ label: 'Upload', path: '/' }];

export const NavBar: React.FC<INavBarProps> = ({ user, logout, showhelp }) => {
  const location = useLocation();

  return (
    <ApAuthenticationContextConsumer>
      {({ user, logout }) => (
        <ApHeaderBar
          className="nav-bar"
          application={{
            title: 'Chapter 11 - Automated Form Tool',
            shortTitle: 'C11',
            color: colors.green1,
            link: '/',
          }}
          rightMenu={() => (
            <>
              {TABS.map(({ label, path }, index) => {
                return (
                  <ApButtonTab
                    isSelected={location.pathname.indexOf(path) === 0}
                    theme="dark"
                    key={`tab-${index}`}
                  >
                    {label}
                  </ApButtonTab>
                );
              })}
              <ApHeaderBarDivider />
              {showhelp && (
                <ApButtonIcon
                  iconName="baseline_help_outline"
                  theme="dark"
                  onClick={showhelp}
                />
              )}
              <ApMenuUser
                initials={user ? getInitials(user?.profile?.name) : ''}
              >
                {logout && <ApMenuItem onClick={logout}>Logout</ApMenuItem>}
              </ApMenuUser>
            </>
          )}
        />
      )}
    </ApAuthenticationContextConsumer>
  );
};
