import React, { FunctionComponent } from 'react';
import { ApButtonMain } from '@alixpartners/ui-components';

import { Box } from '../components/Box';

interface IErrorPageProps {
  title: string;
  description: any;
  onClick: any;
}

const ErrorPage: FunctionComponent<IErrorPageProps> = ({
  title,
  description,
  onClick
}) => {
  return (
    <Box>
      <h2>{title}</h2>
      <p>{description}</p>
      <ApButtonMain onClick={onClick}>TRY AGAIN</ApButtonMain>
    </Box>
  );
};

export default ErrorPage;
