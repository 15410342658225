import React from 'react';
import { Box } from 'components/Box';
import { ApButtonSecondary } from '@alixpartners/ui-components';
import { UploadFileForm } from 'components/UploadFileForm';

interface Props {
  onFileReceived: (file: File) => void;
  loading?: boolean;
}

export const UploadFileSection = ({
  onFileReceived,
  loading = false
}: Props) => {
  return (
    <Box>
      <div className="upload-top-area">
        <h4>Chapter 11 MOR / PCR Form Tool</h4>
        <p>
          Please fill in the template file with the correct data and then upload
          the completed file.
        </p>
      </div>
      <div className="upload-excel_templates">
        <ApButtonSecondary
          href="/templates/MOR_Template.xlsx"
          target="_blank"
          iconName="download"
          as="a"
        >
          Download MOR Template File
        </ApButtonSecondary>
        <ApButtonSecondary
          href="/templates/PCR_Template.xlsx"
          target="_blank"
          iconName="download"
          as="a"
        >
          Download PCR Template File
        </ApButtonSecondary>
      </div>
      <hr className="upload-hr" />
      <div className="upload-bottom-area">
        <h5>Upload your file</h5>
        <p>Please upload your completed template here.</p>
        <UploadFileForm isLoading={loading} onFileDrop={onFileReceived} />
      </div>
    </Box>
  );
};
