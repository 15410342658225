import XLSX from 'xlsx';

import { FileDataType } from 'types/excelTypes';
import { parseExcel } from './excelParser/excelParser';
import { validateExcelData } from './excelParser/common/excelValidation';

export const readAndParseExcel = async (file: File) => {
  const excelContent = await readExcelFile(file);
  const fileInfo = getExcelFileInfo(file, excelContent);
  const excelType = fileInfo.type;

  const entityValues = parseExcel(excelContent, excelType);
  const { entities, error } = await validateExcelData(entityValues, excelType);

  // console.log('entitiesData', entities);

  return { fileInfo, entities, error };
};

export const readExcelFile = (file: any): Promise<XLSX.WorkBook> =>
  new Promise<XLSX.WorkBook>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onerror = reject;
    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      const bufferArray = e.target?.result;
      const result = XLSX.read(bufferArray, { type: 'array' });
      resolve(result);
    };
  });

export const getExcelFileInfo = (file: File, excelContent: XLSX.WorkBook) => {
  return {
    name: file.name,
    size: file.size,
    type: getExcelType(excelContent),
  };
};

const getExcelType = (data: XLSX.WorkBook): FileDataType => {
  const setUpSheet = data.Sheets['Set-Up'];
  const title = (setUpSheet?.A2?.v as string) || '';

  if (title.includes('MOR')) return FileDataType.MOR;
  if (title.includes('PCR')) return FileDataType.PCR;
  return FileDataType.Unknwown;
};
