import { trackEvent } from 'utils/common/telemetry';
import { IRequestError } from 'api/requestTypes';
import { useState } from 'react';
import { IEntityData, IExcelData } from 'types/excelTypes';
import { ePage } from 'types/pageType';
import { readAndParseExcel } from 'utils/excel';
import {
  downloadAllFinalPdfFiles,
  downloadAllPdfFiles,
  downloadPdfFile
} from 'utils/pdf/pdfFile';

export const useMainPage = () => {
  const [page, setPage] = useState(ePage.Main);
  const [requestError, setRequestError] = useState<IRequestError>();
  const [excelData, setExcelData] = useState<IExcelData>();
  const [validateEntity, setValidateEntity] = useState<IEntityData>();

  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingEntity, setLoadingEntity] = useState<boolean[]>([]);
  const [loadingAllEntity, setLoadingAllEntity] = useState(false);
  const [loadingAllFinalEntity, setLoadingAllFinalEntity] = useState(false);

  const setLoadingEntityId = (itemId: number, loading: boolean) =>
    setLoadingEntity((val) => {
      val[itemId] = loading;
      return [...val];
    });

  const clearRequestError = () => setRequestError(undefined);

  const showPageMain = () => {
    setExcelData(undefined);
    clearRequestError();
    setPage(ePage.Main);
  };

  const showPageEntityList = () => {
    clearRequestError();
    setPage(ePage.EntityList);
  };

  const showEntityValidationPage = (id: string) => {
    const itemId = +id;
    const item = excelData?.entities[itemId];
    if (!item) return;

    setValidateEntity(item);
    setPage(ePage.EnityValidation);
  };

  const closeEntityValidationPage = () => {
    setValidateEntity(undefined);
    showPageEntityList();
  };

  const uploadExcelFile = async (file: File) => {
    try {
      setLoadingExcel(true);
      const { fileInfo, entities, error } = await readAndParseExcel(file);
      setRequestError(error);
      setExcelData({ fileInfo, entities });
    } finally {
      setLoadingExcel(false);
      setPage(ePage.ExcelSummary);
    }
  };

  const downloadEndityPdf = async (id: string) => {
    const itemId = +id;
    const item = excelData?.entities[itemId];
    if (!item) return;

    trackEvent('Download Editable File', item.info);

    try {
      setLoadingEntityId(itemId, true);
      const result = await downloadPdfFile(item, excelData.fileInfo.type);
      setRequestError(result?.error);
    } finally {
      setLoadingEntityId(itemId, false);
    }
  };

  const downloadAllPdf = async () => {
    if (!excelData) return;

    trackEvent('Download All Editable Files', { numberOfEntities: excelData.entities.length });

    try {
      setLoadingAllEntity(true);
      const result = await downloadAllPdfFiles(excelData);
      setRequestError(result?.error);
    } finally {
      setLoadingAllEntity(false);
    }
  };

  const downloadAllFinalPdf = async () => {
    if (!excelData) return;

    trackEvent('Download All Final PDFs', { numberOfEntities: excelData.entities.length });

    try {
      setPage(ePage.PdfFinalProcessing);
      setLoadingAllFinalEntity(true);
      const result = await downloadAllFinalPdfFiles(excelData);
      if(result?.error) trackEvent('Download All Final PDF Error', { error: result.error });
      setRequestError(result?.error);
    } finally {
      setLoadingAllFinalEntity(false);
    }
  };

  return {
    data: {
      page,
      requestError,
      loadingExcel,
      loadingEntity,
      loadingAllEntity,
      loadingAllFinalEntity,
      excelData,
      validateEntity
    },
    action: {
      showPageMain,
      showPageEntityList,
      showEntityValidationPage,
      clearRequestError,
      closeEntityValidationPage,

      uploadExcelFile,
      downloadEndityPdf,
      downloadAllPdf,
      downloadAllFinalPdf
    }
  };
};
