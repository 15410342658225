import { IEntityData } from 'types/excelTypes';

export const niceBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return '0 b';

  const k = 1024;
  const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const value = bytes / Math.pow(k, i);

  return value.toFixed(decimals) + ' ' + sizes[i];
};

export const calcFinalPdfLoadingTimeMs = (data: IEntityData[]): number => {
  const timeSec = 10 + data.length * 4;
  return timeSec * 1000;
};
