/* prettier-ignore */
import React from 'react'

const ASSETS = {
  search: {
    data: (
      <g>
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </g>
    ),
    width: 24,
    height: 24
  },
  folder: {
    data: (
      <path d="M10 4l2 2h8c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2l.01-12c0-1.1.89-2 1.99-2h6zm1.17 4l-2-2H4v12h16V8h-8.83zm2.84 4V9L18 13l-3.99 4v-3H7v-2h7.01z" />
    ),
    width: 24,
    height: 24
  },
  shell: {
    data: (
      <path d="M19.66 14C19 15.92 17.42 17.54 15.26 18.39L14 18.88V20H10V18.88L8.73 18.38C6.57 17.53 4.99 15.91 4.33 13.99H19.66V14ZM12 2C6.48 2 2 6.48 2 12C2 15.69 4.47 18.86 8 20.25V22H16V20.25C19.53 18.86 22 15.69 22 12C22 6.48 17.52 2 12 2ZM10 12V4.26C10.64 4.1 11.31 4 12 4C12.69 4 13.36 4.1 14 4.26V12H10ZM16 12V5.08C18.39 6.47 20 9.04 20 12H16ZM4 12C4 9.05 5.61 6.47 8 5.08V12H4Z" />
    ),
    width: 24,
    height: 24
  }
};

export default ASSETS;
