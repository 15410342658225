import { useEffect } from 'react';
import { trackEvent } from 'utils/common/telemetry';
import { BoxSection } from 'components/BoxSection';
import { ProgressBar } from 'components/ProgressBar';
import { IEntityData } from 'types/excelTypes';
import { calcFinalPdfLoadingTimeMs } from 'utils/common/fileUtils';
import { useTimerProgress } from 'utils/common/useTimerProgress';
import { IRequestError } from 'api/requestTypes';
import { BoxSectionRequestError } from 'components/ErrorMessage/ErrorMessage';

interface IProps {
  data: IEntityData[] | undefined;
  loading: boolean;
  requestError: IRequestError | undefined;
  onBack: () => void;
}

export const PdfProcessingSection = ({
  data = [],
  loading,
  requestError,
  onBack,
}: IProps) => {
  // let set max progress value to 95%
  // to prevent situation when progress value is 100% but processing is not completed
  const { progress, startTimer, stopTimer } = useTimerProgress(0, 95);

  useEffect(() => {
    if (!loading) {
      stopTimer();
      return;
    }

    const totalTime = calcFinalPdfLoadingTimeMs(data);
    startTimer(totalTime);

    return () => stopTimer();
  }, [data, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const bottomBarProps = { onBack };

  if (requestError) {
    return (
      <BoxSectionRequestError
        bottomBar={bottomBarProps}
        requestError={requestError}
      />
    );
  }

  if (!loading)
    trackEvent('File download complete', {
      numberOfEntities: data.entries.length,
    });

  return (
    <BoxSection
      title="Verifying Forms"
      description="The forms are being verified and rendered, this may take several minutes."
      bottomBar={{ onBack }}
    >
      <div className="pdf-processing processing-box">
        <div className="upload-complete_Item">
          <ProgressBar
            caption={
              loading
                ? 'Processing...'
                : 'Complete, your download with start automatically'
            }
            value={loading ? progress : 100}
          />
        </div>
      </div>
    </BoxSection>
  );
};
