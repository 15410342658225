import { ApButtonMain, ApButtonSecondary } from '@alixpartners/ui-components';
import { Box } from 'components/Box';

import './BoxSection.css';

interface Props {
  title: string;
  description?: React.ReactNode;
  children: React.ReactNode;
  bottomBar?: IBottomBarProps;
}

export interface IBottomBarProps {
  hide?: boolean;
  btnNext?: string;
  btnNextIcon?: string;
  btnNextDisabled?: boolean;
  onBack?: () => void;
  onForward?: () => void;
}

export const BoxSection = ({
  title,
  description,
  bottomBar,
  children,
}: Props) => {
  return (
    <Box>
      <div className="box-section" style={{ width: 800 }}>
        <div className="box-section_title"> {title} </div>
        {description && (
          <div className="box-section_description"> {description}</div>
        )}
        <div className="box-section_body">{children}</div>
        <div className="box-section_bottom">
          <BoxBottomBar {...bottomBar} />
        </div>
      </div>
    </Box>
  );
};

const BoxBottomBar = (props: IBottomBarProps) => {
  if (props.hide) return null;
  return (
    <div className="entity-list_bottom">
      <ApButtonSecondary
        className="entity-list_btn-back"
        onClick={props.onBack}
        iconName="baseline_arrow_back"
      >
        BACK
      </ApButtonSecondary>

      <div className="entity-list_space" />

      {props.btnNext ? (
        <ApButtonMain
          className="entity-list_btn-downloadallfinal"
          autoFocus={false}
          disabled={props.btnNextDisabled}
          iconName={props.btnNextIcon}
          theme="light"
          onClick={props.onForward}
        >
          {props.btnNext}
        </ApButtonMain>
      ) : null}
    </div>
  );
};
