import {
  ApButtonGroup,
  ApButtonTab,
  ApButtonSecondary,
  ApModal,
} from '@alixpartners/ui-components';
import { trackEvent } from 'utils/common/telemetry';
import { Box } from 'components/Box';
import { CheckTable } from 'components/CheckTable';
import { useEffect, useMemo, useState } from 'react';
import { FileDataType, IEntityData } from 'types/excelTypes';
import { flattenObjectShell } from 'utils/common/dataUtils';
import { validationErrorText } from 'utils/common/validationUtils';
import { IconValidationError } from 'components/IconDefault';

interface Props {
  data: IEntityData | undefined;
  type: FileDataType | undefined;
  onClose: () => void;
}

const defaultData = {
  workbook: {},
  info: {},
} as IEntityData;

export const ValidateSection = ({
  data = defaultData,
  type = FileDataType.Unknwown,
  onClose,
}: Props) => {
  const { workbook, validationInfo, info } = data;
  const { allSheets, allSheetsName } = useMemo(() => {
    const allSheets = flattenObjectShell(workbook);
    const allSheetsName = Object.keys(allSheets);
    return { allSheets, allSheetsName };
  }, [workbook]);

  const [activeSheet, setActiveSheetName] = useState(allSheetsName[0] || '');
  const [showInfo, setShowInfo] = useState(false);

  const { sheetData, sheetValidation } = useMemo(() => {
    return {
      sheetData: allSheets[activeSheet as keyof typeof allSheets] ?? [],
      sheetValidation: validationInfo[activeSheet] ?? [],
    };
  }, [activeSheet, allSheets, validationInfo]);

  useEffect(() => {
    trackEvent('Results of Validation', {
      numberOfSheets: allSheetsName.length,
      numberOfSheetPassed: allSheetsName.filter(
        (sheet) => !validationInfo[sheet],
      ).length,
      numberOfSheetErrors: allSheetsName.filter(
        (sheet) => !!validationInfo[sheet],
      ).length,
      totalErrors: info.errorCount,
    });
  }, [allSheetsName, validationInfo, info]);

  return (
    <>
      <Box>
        <p className="mb-32">Please validate the information provided</p>
        <ApButtonGroup justifyContent="initial" spacingSize={0}>
          {allSheetsName.map((sheet: string, id: number) => {
            const hasError = !!validationInfo[sheet];
            return (
              <ApButtonTab
                key={`tab-${id}`}
                onClick={() => setActiveSheetName(sheet)}
                isSelected={activeSheet === sheet}
              >
                {hasError ? <IconValidationError /> : null}
                {sheet}
              </ApButtonTab>
            );
          })}
        </ApButtonGroup>
        <div className="table-container">
          <CheckTable
            data={sheetData}
            validationSheetInfo={sheetValidation}
            type={type}
          />
          <div className="validation-result">
            <p onClick={() => setShowInfo(true)} className="no-select">
              There are currently <strong>{info.errorCount}</strong> errors.
            </p>
          </div>
          <div className="split-content">
            <ApButtonSecondary onClick={onClose} iconName="baseline_arrow_back">
              BACK
            </ApButtonSecondary>
          </div>
        </div>
      </Box>

      {showInfo ? (
        <ApModal
          hasClosed={() => setShowInfo(false)}
          header="Errors list"
          isDismissible={true}
          isOpen={showInfo}
          className="message-dialog"
          isFluid
        >
          <div className="validation-dialog">
            {validationErrorText(validationInfo, type)}
          </div>
        </ApModal>
      ) : null}
    </>
  );
};
