import React from 'react';
import ASSETS from './Icon.constants';
import './Icon.css';

interface IProps {
  name: keyof typeof ASSETS;
  fill?: string;
  width?: null | number;
  height?: null | number;
  disabled?: boolean;
  stroke?: string;
  className?: string;
  innerRef?: any;
}

export const Icon = ({
  name,
  fill,
  width,
  height,
  disabled,
  stroke,
  className,
  innerRef
}: IProps): any => {
  const asset = ASSETS[name];
  if (!asset) return;

  const scaleWidth = width && width / asset.width;
  const scaleHeight = height && height / asset.height;
  const scaleFactor = Math.max(scaleWidth || 1, scaleHeight || 1);
  const scaledWidth = asset.width * scaleFactor;
  const scaledHeight = asset.height * scaleFactor;

  return (
    <div ref={innerRef} role="presentation" className={`icon ${className}`}>
      <svg
        className={`icon-image icon-${name}`}
        width={scaledWidth}
        height={scaledHeight}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${asset.width} ${asset.height}`}
      >
        <g
          fill={!disabled ? fill : 'var(--ap-color-ui-grey-dark)'}
          stroke={stroke}
        >
          {asset.data}
        </g>
      </svg>
    </div>
  );
};
