import { trackPage } from '../../utils/common/telemetry';

import { UploadFileSection } from './sections/UploadFileSection';
import { EntitiesListSection } from './sections/EntitiesListSection';
import { ValidateSection } from './sections/ValidateSection';
import { ExcelProsessingSection } from './sections/ExcelProsessingSection';
import { PdfProcessingSection } from './sections/PdfProcessingSection';
import { ePage } from 'types/pageType';
import { useMainPage } from './useMainPage';

import './MainPage.css';

export const MainPage = () => {
  const { data, action } = useMainPage();

  const renderSection = () => {
    switch (data.page) {
      case ePage.Main:
        trackPage('Main');
        return (
          <UploadFileSection
            onFileReceived={action.uploadExcelFile}
            loading={data.loadingExcel}
          />
        );

      case ePage.ExcelSummary:
        trackPage('Excel Summary');
        return (
          <ExcelProsessingSection
            fileInfo={data.excelData?.fileInfo}
            requestError={data.requestError}
            onBack={action.showPageMain}
            onForward={action.showPageEntityList}
          />
        );

      case ePage.EntityList:
        trackPage('Entitry List');
        return (
          <EntitiesListSection
            data={data.excelData?.entities}
            loadingAll={data.loadingAllEntity}
            entityLoading={data.loadingEntity}
            requestError={data.requestError}
            onCloseErrorDialog={action.clearRequestError}
            onValidateClick={action.showEntityValidationPage}
            onDowndloadClick={action.downloadEndityPdf}
            onDownloadAllClick={action.downloadAllPdf}
            onDownloadFinalAllClick={action.downloadAllFinalPdf}
            onBack={action.showPageMain}
          />
        );

      case ePage.EnityValidation:
        trackPage('Entity Validation');
        return (
          <ValidateSection
            data={data.validateEntity}
            onClose={action.closeEntityValidationPage}
            type={data.excelData?.fileInfo.type}
          />
        );

      case ePage.PdfFinalProcessing:
        trackPage('PDF Processing');
        return (
          <PdfProcessingSection
            data={data.excelData?.entities}
            loading={data.loadingAllFinalEntity}
            requestError={data.requestError}
            onBack={action.showPageEntityList}
          />
        );
    }
  };

  return <section className="page-homepage">{renderSection()}</section>;
};
