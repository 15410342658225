import _ from 'lodash';
import { pageMor } from 'types/excelTypes';
import { toCamelCase } from 'utils/common/dataUtils';
import { IMemberSheetFieled } from './mappingTypes';

export const memberConverterMor = (
  memberNames: string[],
): IMemberSheetFieled => {
  const members = toCamelCase(memberNames);
  const result =
    getMemberSheetForAdditionaData(members) ?? getMemberSheetField(members);
  return result;
};

/**
 * Convert back-end members list for section 5a and 5b - into structure with Excel names
 *
 * For example - members:
 *   ["Part5_ProfessionalFeesAndExpenses", "BankruptcyFirms", "0", "FirmName"]
 * will return next structure
 *   {
 *     sheet: 'Part 5a',
 *     recordId: '0',
 *     field: 'FirmName'
 *   }
 */
const getMemberSheetForAdditionaData = (
  members: string[],
): IMemberSheetFieled | undefined => {
  //"Page 5a" and "Page 5b" two sheets contains tables with additional data
  const [sheet, section, recordId, subField] = members;
  if (sheet !== additionalDataPart) return undefined;

  let sheetName = _.get(mapMorAdditionalNames, section) as string;
  if (!sheetName) {
    console.error(`${section} field not found; \n members = "${members}"`);
    sheetName = section;
  }

  return {
    sheet: sheetName,
    recordId: +recordId,
    field: subField,
  };
};

/**
 * Convert back-end members list - into structure with Excel names
 *
 * For example - members: ["FirstPage", "CaseNo"]
 * will return next structure
 *   {
 *     sheet: 'Page 1',
 *     recordId: 'Case No.',
 *     field: 'value'
 *   }
 */
const getMemberSheetField = (members: string[]): IMemberSheetFieled => {
  const [sheet, recordNameDef] = members;

  let sheetName = _.get(mapMorPagesNames, sheet) as string;
  let recordName = _.get(mapMorStruct, members) as string;

  if (!sheetName) {
    console.error(`${sheet} field not found; \n members = "${members}"`);
    sheetName = sheet;
  }

  if (!recordName) {
    console.error(`${recordName} field not found; \n members = "${members}"`);
    recordName = recordNameDef;
  }

  return {
    sheet: sheetName,
    recordId: recordName,
    field: 'value',
  };
};

export const additionalDataPart = 'part5_ProfessionalFeesAndExpenses';

const mapMorPagesNames = {
  firstPage: pageMor.firstPage,
  part1_CashReceiptsAndDisbursements: pageMor.parts1_4,
  part2_AssetsAndLiabilityStatus: pageMor.parts1_4,
  part3_AssetsSoldOrTransferred: pageMor.parts1_4,
  part4_IncomeStatement: pageMor.parts1_4,
  part6_PostPetitionTaxes: pageMor.parts5_8,
  part7_Questionnaire: pageMor.parts5_8,
  part8_IndividualChapter11Debtors: pageMor.parts5_8,
  final_Signatures: pageMor.finalPage,
};

const mapMorAdditionalNames = {
  bankruptcyFirms: pageMor.part_5a,
  nonBankruptcyFirms: pageMor.part_5b,
  allFirm_Approved_Current: pageMor.part_5c,
  allFirm_Approved_Cumulative: pageMor.part_5c,
  allFirm_Paid_Current: pageMor.part_5c,
  allFirm_Paid_Cumulative: pageMor.part_5c,
};

// this object is used in validation.
// keep structure same as in the request
export const mapMorStruct = {
  firstPage: {
    districtQualifier: 'District Qualifier',
    districtState: 'District State',
    courtDivision: 'Court Division',
    debtors: 'Debtor(s)',
    caseNo: 'Case No.',
    leadCaseNo: 'Lead Case No.',
    isJointlyAdministered: 'Jointly Administered',
    reportType: 'Report Type',
    reportingPeriodEnded: 'Reporting Period Ended',
    petitionDate: 'Petition Date',
    industryClassification: 'Industry Classification',
    reportingMethod: 'Reporting Method',
    debtorFullTimeEmployees_Current: "Debtor's Full-Time Employees (current)",
    debtorFullTimeEmployees_AsOfDateOfOrder:
      "Debtor's Full-Time Employees (as of date of order for relief)",
    isCashFlowStatementAttached: 'Statement of cash receipts and disbursements',
    isBalanceSheetStatementAttached:
      'Balance sheet containing the summary and detail of the assets, liabilities and equity (net worth) or deficit',
    isProfitAndLossStatementAttached:
      'Statement of operations (profit or loss statement)',
    isAccountsReceivableAgingAttached: 'Accounts receivable aging',
    isPostPetitionLiabilitiesAgingAttached: 'Postpetition liabilities aging',
    isStatementOfCapitalAssetsAttached: 'Statement of capital assets',
    isScheduleOfPaymentsToProfessionalsAttached:
      'Schedule of payments to professionals',
    isScheduleOfPaymentsToInsidersAttached: 'Schedule of payments to insiders',
    isAllBankStatementsAndReconcilationAttached:
      'All bank statements and bank reconciliations for the reporting period',
    isDescOfAssetsSoldOrTransferredAttached:
      'Description of the assets sold or transferred and the terms of the sale or transfer',
    responsiblePartySignature: 'Signature of Responsible Party',
    responsiblePartyName: 'Printed Name of Responsible Party',
    signedDate: 'Date',
    responsiblePartyAddress: 'Address',
  },
  part1_CashReceiptsAndDisbursements: {
    cashBalanceBeginningOfMonth_Current: 'Cash balance beginning of month',
    totalReceipts_Current:
      'Total receipts (net of transfers between accounts) - Current',
    totalReceipts_Cumulative:
      'Total receipts (net of transfers between accounts) - Cumulative',
    totalDisbursements_Current:
      'Total disbursements (net of transfers between accounts) - Current',
    totalDisbursements_Cumulative:
      'Total disbursements (net of transfers between accounts) - Cumulative',
    disbursementsMadeByThirdParty_Current:
      'Disbursements made by third party for the benefit of the estate - Current',
    disbursementsMadeByThirdParty_Cumulative:
      'Disbursements made by third party for the benefit of the estate - Cumulative',
  },
  part2_AssetsAndLiabilityStatus: {
    accountsReceivable: 'Accounts receivable (total net of allowance)',
    accountsReceivable_Over90Days:
      'Accounts receivable over 90 days outstanding (net of allowance)',
    inventoryType: 'Inventory Type',
    inventory: 'Inventory',
    totalCurrentAssets: 'Total current assets',
    totalAssets: 'Total assets',
    postPetitionPaybles_Excluding_Taxes:
      'Postpetition payables (excluding taxes)',
    postPetitionPaybles_PastDues_Excluding_Taxes:
      'Postpetition payables past due (excluding taxes)',
    postPetition_Taxes_Payable: 'Postpetition taxes payable',
    postPetition_Taxes_PastDue: 'Postpetition taxes past due',
    prePetitionSecuredDebt: 'Prepetition secured debt',
    prePetitionPriorityDebt: 'Prepetition priority debt',
    prePetitionUnSecuredDebt: 'Prepetition unsecured debt',
  },
  part3_AssetsSoldOrTransferred: {
    totalCashSales_Current:
      'Total cash sales price for assets sold/transferred outside the ordinary course of business - Current',
    totalCashSales_Cumulative:
      'Total cash sales price for assets sold/transferred outside the ordinary course of business - Cumulative',
    totalPayments_Current:
      'Total payments to third parties incident to assets being sold/transferred outside the ordinary course of business - Current',
    totalPayments_Cumulative:
      'Total payments to third parties incident to assets being sold/transferred outside the ordinary course of business - Cumulative',
  },
  part4_IncomeStatement: {
    grossIncome: 'Gross income/sales (net of returns and allowances)',
    costOfGoodsSold:
      'Cost of goods sold (inclusive of depreciation, if applicable)',
    sellingExpenses: 'Selling expenses',
    generalAndAdministrativeExpenses: 'General and administrative expenses',
    otherExpenses: 'Other expenses',
    depreciationAndAmortization:
      'Depreciation and/or amortization (not included in 4b)',
    interest: 'Interest',
    taxes: 'Taxes (local, state, and federal)',
    reorganizationItems: 'Reorganization items',
    profit_Loss_Current: 'Profit (loss) - Current',
    profit_Loss_Cumulative: 'Profit (loss) - Cumulative',
  },
  // part5_ProfessionalFeesAndExpenses: { }, // arrays with additional data.
  part6_PostPetitionTaxes: {
    incomeTax_Accrued_CurrentMonth:
      'Postpetition income taxes accrued (local, state, and federal) - Current',
    incomeTax_Accrued_Cumulative:
      'Postpetition income taxes accrued (local, state, and federal) - Cumulative',
    incomeTax_Paid_CurrentMonth:
      'Postpetition income taxes paid (local, state, and federal) - Current',
    incomeTax_Paid_Cumulative:
      'Postpetition income taxes paid (local, state, and federal) - Cumulative',
    employerPayrollTax_Accrued_CurrentMonth:
      'Postpetition employer payroll taxes accrued - Current',
    employerPayrollTax_Accrued_Cumulative:
      'Postpetition employer payroll taxes accrued - Cumulative',
    employerPayrollTax_Paid_CurrentMonth:
      'Postpetition employer payroll taxes paid - Current',
    employerPayrollTax_Paid_Cumulative:
      'Postpetition employer payroll taxes paid - Cumulative',
    propertyTax_Paid_CurrentMonth: 'Postpetition property taxes paid - Current',
    propertyTax_Paid_Cumulative:
      'Postpetition property taxes paid - Cumulative',
    otherTaxes_Accrued_CurrentMonth:
      'Postpetition other taxes accrued (local, state, and federal) - Current',
    otherTaxes_Accrued_Cumulative:
      'Postpetition other taxes accrued (local, state, and federal) - Cumulative',
    otherTaxes_Paid_CurrentMonth:
      'Postpetition other taxes paid (local, state, and federal) - Current',
    otherTaxes_Paid_Cumulative:
      'Postpetition other taxes paid (local, state, and federal) - Cumulative',
  },
  part7_Questionnaire: {
    isPayments_Made_on_Prepetition_Debt:
      'Were any payments made on prepetition debt? (if yes, see Instructions)',
    isPayments_made_outside_ordinary_course_of_business:
      'Were any payments made outside the ordinary course of business without court approval? (if yes, see Instructions)',
    isPayments_made_to_or_on_behalf_of_insiders:
      'Were any payments made to or on behalf of insiders?',
    are_you_current_on_postpetition_tax_return_filings:
      'Are you current on postpetition tax return filings?',
    are_you_current_on_postpetition_estimated_tax_payments:
      'Are you current on postpetition estimated tax payments?',
    is_trust_fund_taxes_remitted:
      'Were all trust fund taxes remitted on a current basis?',
    is_there_any_postpetition_borrowing_other_than_trade_credit:
      'Was there any postpetition borrowing, other than trade credit? (if yes, see Instructions)',
    is_all_payments_made_to_professionals:
      'Were all payments made to or on behalf of professionals approved by the court?',
    do_you_have_workesrs_compensation_insurance:
      "Do you have Worker's compensation insurance?",
    is_workesrs_compensation_insurance_premiums_current:
      'If compensation insurance, are your premiums current? (if No, see Instructions)',
    do_you_have_casualty_property_insurance:
      'Do you have Casualty/property insurance?',
    is_casualty_property_insurance_premiums_current:
      'If casualty/property insurance, are your premiums current? (if No, see Instructions)',
    do_you_have_general_liability_insurance:
      'Do you have General liability insurance?',
    is_general_liability_insurance_premiums_current:
      'If General liability insurance, are your premiums current? (if No, see Instructions)',
    has_a_plan_of_reorganization_been_filed_with_the_court:
      'Has a plan of reorganization been filed with the court?',
    has_a_disclosure_statement_been_filed_with_the_court:
      'Has a disclosure statement been filed with the court?',
    are_you_current_with_quarterly_USTrustee_fees_as_set_forth_under_28USC:
      'Are you current with quarterly U.S. Trustee fees as set forth under 28 U.S.C. 1930?',
  },
  part8_IndividualChapter11Debtors: {
    grossIncome_receipts_from_salary_and_wages:
      'Gross income (receipts) from salary and wages',
    grossIncome_receipts_from_self_employment:
      'Gross income (receipts) from self-employment',
    grossIncome_OtherSources: 'Gross income from all other sources',
    payrollDeductions: 'Payroll deductions',
    selfEmploymentExpenses: 'Self-employment related expenses',
    livingExpenses: 'Living expenses',
    otherExpenses: 'All other expenses',
    postPetitionDebts_PastDue:
      'List the total amount of all postpetition debts that are past due',
    are_you_required_to_pay_any_Domestic_Support_Obligations:
      'Are you required to pay any Domestic Support Obligations as defined by 11 U.S.C. 101(14A)?',
    is_all_Domestic_Support_Obligation_payments_paid:
      'If yes, have you made all Domestic Support Obligation payments?',
  },
  final_Signatures: {
    partyTitle: 'Title',
    partyName: 'Printed Name of ResponsibleParty',
    partySignature: 'Signature of Responsible Party',
    signaturedDate: 'Date',
  },
};
