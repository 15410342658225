import _ from 'lodash';

import { IMorEntityWorkbook, IFeesExpensesValue } from 'types/excelTypes';
import {
  sheetBool,
  sheetBoolEnum,
  sheetCaseNumber,
  sheetDate,
  sheetInventoryType,
  sheetNumber,
  sheetText,
  sheetTextDef,
  sheetTextNoSpace,
} from './mappingUtils';
import { mapMorStruct } from './mapStructureMor';

//to shortenning parameters
const fieldName = {
  first: mapMorStruct.firstPage,
  part1: mapMorStruct.part1_CashReceiptsAndDisbursements,
  part2: mapMorStruct.part2_AssetsAndLiabilityStatus,
  part3: mapMorStruct.part3_AssetsSoldOrTransferred,
  part4: mapMorStruct.part4_IncomeStatement,
  part6: mapMorStruct.part6_PostPetitionTaxes,
  part7: mapMorStruct.part7_Questionnaire,
  part8: mapMorStruct.part8_IndividualChapter11Debtors,
  final: mapMorStruct.final_Signatures,
};

/* prettier-ignore */

export const mappingMor = (data: IMorEntityWorkbook) => {

  const pagerFirst = data.fields['Page 1'];
  const pagePart1_4 = data.fields['Parts 1-4'];
  const pagePart5_8 = data.fields['Parts 5-8'];
  const pagefinal = data.fields['Responsible Party'];

  const part5a = data.feesAndExpenses['Part 5a'];
  const part5b = data.feesAndExpenses['Part 5b'];
  const part5c = sheet5cSummaryValue(data.feesAndExpenses['Part 5c'], part5a, part5b)

  const result = {
    firstPage: {
      districtQualifier: sheetText(pagerFirst, fieldName.first.districtQualifier),
      districtState: sheetText(pagerFirst, fieldName.first.districtState),
      courtDivision: sheetText(pagerFirst, fieldName.first.courtDivision),
      debtors: sheetText(pagerFirst, fieldName.first.debtors),
      caseNo: sheetCaseNumber(pagerFirst, fieldName.first.caseNo),
      leadCaseNo: sheetCaseNumber(pagerFirst, fieldName.first.leadCaseNo),
      isJointlyAdministered: sheetBool(pagerFirst, fieldName.first.isJointlyAdministered),
      reportType: sheetTextNoSpace(pagerFirst, fieldName.first.reportType),
      reportingPeriodEnded: sheetDate(pagerFirst, fieldName.first.reportingPeriodEnded),
      petitionDate: sheetDate(pagerFirst, fieldName.first.petitionDate),
      industryClassification: sheetTextDef(pagerFirst, fieldName.first.industryClassification, '0000'),
      reportingMethod: sheetTextNoSpace(pagerFirst, fieldName.first.reportingMethod),
      debtorFullTimeEmployees_Current: sheetText(pagerFirst, fieldName.first.debtorFullTimeEmployees_Current),
      debtorFullTimeEmployees_AsOfDateOfOrder: sheetText(pagerFirst, fieldName.first.debtorFullTimeEmployees_AsOfDateOfOrder),
      isCashFlowStatementAttached: sheetBool(pagerFirst, fieldName.first.isCashFlowStatementAttached),
      isBalanceSheetStatementAttached: sheetBool(pagerFirst, fieldName.first.isBalanceSheetStatementAttached),
      isProfitAndLossStatementAttached: sheetBool(pagerFirst, fieldName.first.isProfitAndLossStatementAttached),
      isAccountsReceivableAgingAttached: sheetBool(pagerFirst, fieldName.first.isAccountsReceivableAgingAttached),
      isPostPetitionLiabilitiesAgingAttached: sheetBool(pagerFirst, fieldName.first.isPostPetitionLiabilitiesAgingAttached),
      isStatementOfCapitalAssetsAttached: sheetBool(pagerFirst, fieldName.first.isStatementOfCapitalAssetsAttached),
      isScheduleOfPaymentsToProfessionalsAttached: sheetBool(pagerFirst, fieldName.first.isScheduleOfPaymentsToProfessionalsAttached),
      isScheduleOfPaymentsToInsidersAttached: sheetBool(pagerFirst, fieldName.first.isScheduleOfPaymentsToInsidersAttached),
      isAllBankStatementsAndReconcilationAttached: sheetBool(pagerFirst, fieldName.first.isAllBankStatementsAndReconcilationAttached),
      isDescOfAssetsSoldOrTransferredAttached: sheetBool(pagerFirst, fieldName.first.isDescOfAssetsSoldOrTransferredAttached),
      responsiblePartySignature: sheetText(pagerFirst, fieldName.first.responsiblePartySignature),
      responsiblePartyName: sheetText(pagerFirst, fieldName.first.responsiblePartyName),
      signedDate: sheetDate(pagerFirst, fieldName.first.signedDate),
      responsiblePartyAddress: sheetText(pagerFirst, fieldName.first.responsiblePartyAddress),
    },
    part1_CashReceiptsAndDisbursements: {
      cashBalanceBeginningOfMonth_Current: sheetNumber(pagePart1_4, fieldName.part1.cashBalanceBeginningOfMonth_Current),
      totalReceipts_Current: sheetNumber(pagePart1_4, fieldName.part1.totalReceipts_Current),
      totalReceipts_Cumulative: sheetNumber(pagePart1_4, fieldName.part1.totalReceipts_Cumulative),
      totalDisbursements_Current: sheetNumber(pagePart1_4, fieldName.part1.totalDisbursements_Current),
      totalDisbursements_Cumulative: sheetNumber(pagePart1_4, fieldName.part1.totalDisbursements_Cumulative),
      disbursementsMadeByThirdParty_Current: sheetNumber(pagePart1_4, fieldName.part1.disbursementsMadeByThirdParty_Current),
      disbursementsMadeByThirdParty_Cumulative: sheetNumber(pagePart1_4, fieldName.part1.disbursementsMadeByThirdParty_Cumulative),
    },
    part2_AssetsAndLiabilityStatus: {
      accountsReceivable: sheetNumber(pagePart1_4, fieldName.part2.accountsReceivable),
      accountsReceivable_Over90Days: sheetNumber(pagePart1_4, fieldName.part2.accountsReceivable_Over90Days),
      inventoryType: sheetInventoryType(pagePart1_4, fieldName.part2.inventoryType),
      inventory: sheetNumber(pagePart1_4, fieldName.part2.inventory),
      totalCurrentAssets: sheetNumber(pagePart1_4, fieldName.part2.totalCurrentAssets),
      totalAssets: sheetNumber(pagePart1_4, fieldName.part2.totalAssets),
      postPetitionPaybles_Excluding_Taxes: sheetNumber(pagePart1_4, fieldName.part2.postPetitionPaybles_Excluding_Taxes),
      postPetitionPaybles_PastDues_Excluding_Taxes: sheetNumber(pagePart1_4, fieldName.part2.postPetitionPaybles_PastDues_Excluding_Taxes),
      postPetition_Taxes_Payable: sheetNumber(pagePart1_4, fieldName.part2.postPetition_Taxes_Payable),
      postPetition_Taxes_PastDue: sheetNumber(pagePart1_4, fieldName.part2.postPetition_Taxes_PastDue),
      prePetitionSecuredDebt: sheetNumber(pagePart1_4, fieldName.part2.prePetitionSecuredDebt),
      prePetitionPriorityDebt: sheetNumber(pagePart1_4, fieldName.part2.prePetitionPriorityDebt),
      prePetitionUnSecuredDebt: sheetNumber(pagePart1_4, fieldName.part2.prePetitionUnSecuredDebt),
    },
    part3_AssetsSoldOrTransferred: {
      totalCashSales_Current: sheetNumber(pagePart1_4, fieldName.part3.totalCashSales_Current),
      totalCashSales_Cumulative: sheetNumber(pagePart1_4, fieldName.part3.totalCashSales_Cumulative),
      totalPayments_Current: sheetNumber(pagePart1_4, fieldName.part3.totalPayments_Current),
      totalPayments_Cumulative: sheetNumber(pagePart1_4, fieldName.part3.totalPayments_Cumulative),
    },
    part4_IncomeStatement: {
      grossIncome: sheetNumber(pagePart1_4, fieldName.part4.grossIncome),
      costOfGoodsSold: sheetNumber(pagePart1_4, fieldName.part4.costOfGoodsSold),
      sellingExpenses: sheetNumber(pagePart1_4, fieldName.part4.sellingExpenses),
      generalAndAdministrativeExpenses: sheetNumber(pagePart1_4, fieldName.part4.generalAndAdministrativeExpenses),
      otherExpenses: sheetNumber(pagePart1_4, fieldName.part4.otherExpenses),
      depreciationAndAmortization: sheetNumber(pagePart1_4, fieldName.part4.depreciationAndAmortization),
      interest: sheetNumber(pagePart1_4, fieldName.part4.interest),
      taxes: sheetNumber(pagePart1_4, fieldName.part4.taxes),
      reorganizationItems: sheetNumber(pagePart1_4, fieldName.part4.reorganizationItems),
      profit_Loss_Current: sheetNumber(pagePart1_4, fieldName.part4.profit_Loss_Current),
      profit_Loss_Cumulative: sheetNumber(pagePart1_4, fieldName.part4.profit_Loss_Cumulative),
    },
    part5_ProfessionalFeesAndExpenses: {
      bankruptcyFirms: sheetPart5Value(part5a),       
      nonBankruptcyFirms: sheetPart5Value(part5b),
      allFirm_Approved_Current:  part5c.currentValue,
      allFirm_Approved_Cumulative: part5c.cumulativeAppr,
      allFirm_Paid_Current: part5c.currentPaid,
      allFirm_Paid_Cumulative: part5c.cumulativePaid,
    },
    part6_PostPetitionTaxes: {
      incomeTax_Accrued_CurrentMonth: sheetNumber(pagePart5_8, fieldName.part6.incomeTax_Accrued_CurrentMonth),
      incomeTax_Accrued_Cumulative: sheetNumber(pagePart5_8, fieldName.part6.incomeTax_Accrued_Cumulative),
      incomeTax_Paid_CurrentMonth: sheetNumber(pagePart5_8, fieldName.part6.incomeTax_Paid_CurrentMonth),
      incomeTax_Paid_Cumulative: sheetNumber(pagePart5_8, fieldName.part6.incomeTax_Paid_Cumulative),
      employerPayrollTax_Accrued_CurrentMonth: sheetNumber(pagePart5_8, fieldName.part6.employerPayrollTax_Accrued_CurrentMonth),
      employerPayrollTax_Accrued_Cumulative: sheetNumber(pagePart5_8, fieldName.part6.employerPayrollTax_Accrued_Cumulative),
      employerPayrollTax_Paid_CurrentMonth: sheetNumber(pagePart5_8, fieldName.part6.employerPayrollTax_Paid_CurrentMonth),
      employerPayrollTax_Paid_Cumulative: sheetNumber(pagePart5_8, fieldName.part6.employerPayrollTax_Paid_Cumulative),
      propertyTax_Paid_CurrentMonth: sheetNumber(pagePart5_8, fieldName.part6.propertyTax_Paid_CurrentMonth),
      propertyTax_Paid_Cumulative: sheetNumber(pagePart5_8, fieldName.part6.propertyTax_Paid_Cumulative),
      otherTaxes_Accrued_CurrentMonth: sheetNumber(pagePart5_8, fieldName.part6.otherTaxes_Accrued_CurrentMonth),
      otherTaxes_Accrued_Cumulative: sheetNumber(pagePart5_8, fieldName.part6.otherTaxes_Accrued_Cumulative),
      otherTaxes_Paid_CurrentMonth: sheetNumber(pagePart5_8, fieldName.part6.otherTaxes_Paid_CurrentMonth),
      otherTaxes_Paid_Cumulative: sheetNumber(pagePart5_8, fieldName.part6.otherTaxes_Paid_Cumulative),
    },
    part7_Questionnaire: {
      isPayments_Made_on_Prepetition_Debt: sheetBool(pagePart5_8, fieldName.part7.isPayments_Made_on_Prepetition_Debt),
      isPayments_made_outside_ordinary_course_of_business: sheetBool(pagePart5_8, fieldName.part7.isPayments_made_outside_ordinary_course_of_business),
      isPayments_made_to_or_on_behalf_of_insiders: sheetBool(pagePart5_8, fieldName.part7.isPayments_made_to_or_on_behalf_of_insiders),
      are_you_current_on_postpetition_tax_return_filings: sheetBool(pagePart5_8, fieldName.part7.are_you_current_on_postpetition_tax_return_filings),
      are_you_current_on_postpetition_estimated_tax_payments: sheetBool(pagePart5_8, fieldName.part7.are_you_current_on_postpetition_estimated_tax_payments),
      is_trust_fund_taxes_remitted: sheetBool(pagePart5_8, fieldName.part7.is_trust_fund_taxes_remitted),
      is_there_any_postpetition_borrowing_other_than_trade_credit: sheetBool(pagePart5_8, fieldName.part7.is_there_any_postpetition_borrowing_other_than_trade_credit),
      is_all_payments_made_to_professionals: sheetBoolEnum(pagePart5_8, fieldName.part7.is_all_payments_made_to_professionals),
      do_you_have_workesrs_compensation_insurance: sheetBool(pagePart5_8, fieldName.part7.do_you_have_workesrs_compensation_insurance),
      is_workesrs_compensation_insurance_premiums_current: sheetBoolEnum(pagePart5_8, fieldName.part7.is_workesrs_compensation_insurance_premiums_current),
      do_you_have_casualty_property_insurance: sheetBool(pagePart5_8, fieldName.part7.do_you_have_casualty_property_insurance),
      is_casualty_property_insurance_premiums_current: sheetBoolEnum(pagePart5_8, fieldName.part7.is_casualty_property_insurance_premiums_current),
      do_you_have_general_liability_insurance: sheetBool(pagePart5_8, fieldName.part7.do_you_have_general_liability_insurance),
      is_general_liability_insurance_premiums_current: sheetBoolEnum(pagePart5_8, fieldName.part7.is_general_liability_insurance_premiums_current),
      has_a_plan_of_reorganization_been_filed_with_the_court: sheetBool(pagePart5_8, fieldName.part7.has_a_plan_of_reorganization_been_filed_with_the_court),
      has_a_disclosure_statement_been_filed_with_the_court: sheetBool(pagePart5_8, fieldName.part7.has_a_disclosure_statement_been_filed_with_the_court),
      are_you_current_with_quarterly_USTrustee_fees_as_set_forth_under_28USC: sheetBool(pagePart5_8, fieldName.part7.are_you_current_with_quarterly_USTrustee_fees_as_set_forth_under_28USC),
    },
    part8_IndividualChapter11Debtors: {
      grossIncome_receipts_from_salary_and_wages: sheetNumber(pagePart5_8, fieldName.part8.grossIncome_receipts_from_salary_and_wages),
      grossIncome_receipts_from_self_employment: sheetNumber(pagePart5_8, fieldName.part8.grossIncome_receipts_from_self_employment),
      grossIncome_OtherSources: sheetNumber(pagePart5_8, fieldName.part8.grossIncome_OtherSources),
      payrollDeductions: sheetNumber(pagePart5_8, fieldName.part8.payrollDeductions),
      selfEmploymentExpenses: sheetNumber(pagePart5_8, fieldName.part8.selfEmploymentExpenses),
      livingExpenses: sheetNumber(pagePart5_8, fieldName.part8.livingExpenses),
      otherExpenses: sheetNumber(pagePart5_8, fieldName.part8.otherExpenses),
      postPetitionDebts_PastDue: sheetNumber(pagePart5_8, fieldName.part8.postPetitionDebts_PastDue),
      are_you_required_to_pay_any_Domestic_Support_Obligations: sheetBool(pagePart5_8, fieldName.part8.are_you_required_to_pay_any_Domestic_Support_Obligations),
      is_all_Domestic_Support_Obligation_payments_paid: sheetBoolEnum(pagePart5_8, fieldName.part8.is_all_Domestic_Support_Obligation_payments_paid),
    },
    final_Signatures: {
      partyTitle: sheetText(pagefinal, fieldName.final.partyTitle),
      partyName: sheetText(pagefinal, fieldName.final.partyName),
      partySignature: sheetText(pagefinal, fieldName.final.partySignature),
      signaturedDate: sheetDate(pagefinal, fieldName.final.signaturedDate),
    }
  }

  return result;
};

const sheetPart5Value = (val: IFeesExpensesValue[]) => {
  return val.map((r) => ({
    firmName: r.firmName,
    roleType: r.roleType,
    approved_Current: r.currentValue || 0,
    approved_Cumulative: r.cumulativeAppr || 0,
    paid_Current: r.currentPaid || 0,
    paid_Cumulative: r.cumulativePaid || 0,
  }));
};

const sheet5cSummaryValue = (
  part5c: IFeesExpensesValue[],
  part5a: IFeesExpensesValue[],
  part5b: IFeesExpensesValue[],
): IFeesExpensesValue => {
  if (part5c.length > 0) return part5c[0];

  const allFirms = _([...part5a, ...part5b]);

  return {
    currentValue: allFirms.map((r) => r.currentValue).sum(),
    cumulativeAppr: allFirms.map((r) => r.cumulativeAppr).sum(),
    currentPaid: allFirms.map((r) => r.currentPaid).sum(),
    cumulativePaid: allFirms.map((r) => r.cumulativePaid).sum(),
  } as IFeesExpensesValue;
};
