import './Help.css';

export const Help = () => {
  return (
    <div className="help-guide">
      <p>
        The intention of this tool is to automate the process of US Chapter 11
        PDF Forms. You can find more information about these forms on the
        Department of Justice website.
      </p>
      <a
        href="https://www.justice.gov/ust/chapter-11-operating-reports"
        target="blank"
      >
        https://www.justice.gov/ust/chapter-11-operating-reports
      </a>
      <p>
        The first step in using this tool is to download a copy of the relevant
        template file available on the homepage. These excel templates comes
        with a series of sheets that must be filled in to complete and validate
        a form.
      </p>

      <img src="/img/sheets.png" alt="Excel sheets" />

      <p>
        The templates allow you to fill in multiple forms via in a single
        template file by simply adding a row to the setup sheet and then adding
        a column for each row created.
      </p>

      <img src="/img/entity_rows.png" alt="Entity list" />

      <img src="/img/entity_cols.png" alt="Entitiy columns" />

      <p>This will enable you to fill in large numbers of entities quickly.</p>

      <p>
        The master column (seen above) is a column that overrides all other
        values. Its purpose is to act as a quick way of filling in large numbers
        of column values as easily as possible.
      </p>

      <p>
        Once the data has been filled into the template file you will be able to
        upload this to the tool - it will automatically recognise which type of
        form you are uploading. However, please be aware not to change the
        structure of the template files you upload as this can have an adverse
        effect on the tools ability to decipher information provided.
      </p>

      <img src="/img/upload_complete.png" alt="Upload complete" />

      <p>
        You will next be greeted with a screen that will go through all of the
        values provided and validate them in great detail. This is all done in
        accordance of the Department of Justice's standard for these form types.
      </p>

      <img src="/img/entity_list.png" alt="Preview forms" />

      <p>
        At this stage you have the option to download each form individually and
        check it if you so wish. These forms are not yet validated with the
        Department of Justice directly and would still require you to manually
        validate each form. You may also download all of them as a packaged Zip
        file.
      </p>

      <img src="/img/verifying.png" alt="Verification" />

      <p className="final">
        The final step in this process is to validate all the newly created
        forms with the Department of Justice themselves. Simply click the
        <strong> Download All</strong> button and a full sense check will occur.
        Once complete your package of files should start downloading
        automatically giving you a folder will all forms complete, validated
        with the DOJ and ready to file.
      </p>
    </div>
  );
};
