import _ from 'lodash';

export const flattenObjectShell = (obj: object): object => {
  const result = _.reduce(
    obj,
    (acc: object, val: object, key: string) =>
      _.isObject(val) ? { ...acc, ...val } : { ...acc, [key]: val },
    {},
  );

  return result;
};

export const flattenObject = (obj: object, initObj: object = {}): object => {
  return _.reduce(
    obj,
    (acc, val, key) =>
      _.isObject(val) ? flattenObject(val, acc) : { ...acc, [key]: val },
    initObj,
  );
};

export const toCamelCase = (items: string[]): string[] =>
  items.map(_.lowerFirst);
