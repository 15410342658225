import { acquireToken } from '@alixpartners/ui-utils';
import { IRequestResult } from './requestTypes';
import { getError, getError400 } from './utils/responceUtils';

const { REACT_APP_API_URL } = process.env;

export const Api = {
  generateMOR: (data: any) => postRequestBlob('api/v1/mor/generateMOR', data),

  generateAllMOR: (data: any) =>
    postRequestBlob('api/v1/mor/generateMORs', data),

  generateAllMORFinal: (data: any) =>
    postRequestBlob('api/v1/mor/generateMORs?final=true', data),

  validateMORs: (data: any) => postRequestJson('api/v1/mor/validateMORs', data),

  generatePCR: (data: any) => postRequestBlob('api/v1/pcr/generatePCR', data),

  generateAllPCR: (data: any) =>
    postRequestBlob('api/v1/pcr/generatePCRs', data),

  generateAllPCRFinal: (data: any) =>
    postRequestBlob('api/v1/pcr/generatePCRs?final=true', data),

  validatePCRs: (data: any) => postRequestJson('api/v1/pcr/validatePCRs', data)
};

enum eResult {
  unknown,
  json,
  blob
}

export const getReguestJson = (url: string) =>
  apiRequest(url, 'GET', null, eResult.json);

export const postRequestJson = (url: string, data?: any) =>
  apiRequest(url, 'POST', data, eResult.json);

export const postRequestBlob = (url: string, data?: any) =>
  apiRequest(url, 'POST', data, eResult.blob);

const apiRequest = async (
  url: string,
  method: string,
  data: any = undefined,
  resultType: eResult = eResult.unknown
): Promise<IRequestResult> => {
  try {
    const token = await acquireToken();
    const request = createHeader(token, method, data);
    const stream = await fetch(`${REACT_APP_API_URL}/${url}`, request);

    if (stream.status >= 200 && stream.status < 210) {
      const data = await getStreamBody(resultType, stream);
      return { data };
    }

    if (stream.status === 400) {
      const error = await stream.json();
      return { data: null, error: getError400(error) };
    }

    return { data: null, error: getError(stream) };
  } catch (error: any) {
    return {
      data: null,
      error: {
        code: 0,
        text: 'Request Failed please contact your IT administrator'
      }
    };
  }
};

const createHeader = (token: string, method: string = 'GET', data?: any) => ({
  headers: {
    'content-type': 'application/json',
    Authorization: `Bearer ${token}`
  },
  method,
  body: JSON.stringify(data)
});

const getStreamBody = async (resultType: eResult, stream: Response) => {
  if (resultType === eResult.blob) return await stream.blob();
  if (resultType === eResult.json) return await stream.json();
  return null;
};
