import { ApplicationInsights } from '@microsoft/applicationinsights-web';

var appInsights: any;

/**
 * Add telemtry with support for React Router 6
 */
export function setupAppInsights(KEY: string | undefined) {
  if(!KEY) return;

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: KEY,
    },
  });
  appInsights.loadAppInsights();

  trackEvent('Application Loaded', false);
}

/**
 * Track an individual event
 */
export const trackEvent = (eventName: string, properties: any) => {
  if(!appInsights) return;

  appInsights.trackEvent({ name: eventName, properties });
}

/** Track a page view */
export const trackPage = (pageName: string) => {
  if(!appInsights) return;

  appInsights.trackPageView({ name: pageName });
}